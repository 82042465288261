import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	Row,
	Col,
	Container,
	Spinner,
	Input,
	FormGroup,
	Button,
	Label,
	Nav,
	NavItem,
	TabContent,
	NavLink,
	TabPane,
	Card,
	CardTitle,
	CardText,
	ModalHeader,
	ModalFooter,
	Modal,
	ModalBody,
	Form,
} from 'reactstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import NavBar from '../components/navbar'
import moment from 'moment'
import { StateContext } from '../utils/context/state_context'
import { MessageList } from 'react-chat-elements'
import { getSavedUserData } from '../utils/storage/local'
import sendIcon from '../assets/images/send2.png'
import { toast } from 'react-toastify'
import 'react-chat-elements/dist/main.css'

const staticChat = [
	{
		position: 'right',
		type: 'text',
		title: 'You',
		text: 'I want to schedule an appointment',
	},
	{
		position: 'left',
		type: 'text',
		title: 'You',
		text: 'I want to schedule an appointment',
	},
	{
		position: 'left',
		type: 'text',
		title: 'You',
		text: 'I want to schedule an appointment',
	},
	{
		position: 'right',
		type: 'text',
		title: 'You',
		text: 'I want to schedule an appointment',
	},
]

function CustomerChat() {
	const [messages, setMessages] = useState([])
	const [inputMessage, setInputMessage] = useState('')
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [userInfoModal, setUserInfoModal] = useState(false)
	const navigate = useNavigate()
	const userData = getSavedUserData()
	const { id } = useParams()
	const [isLoadingAiMessage, setIsLoadingAiMessage] = useState(false)
	const chatSpaceRef = useRef(null)

	// WebSocket
	const socket = useRef(null)
	const [isConnected, setIsConnected] = useState(false)
	const [members, setMembers] = useState([])

	const { subscriptionData, setSubscriptionData } =
		React.useContext(StateContext)

	// Go to the bottom of the chat && focus on the input
	useEffect(() => {
		if (chatSpaceRef.current) {
			setTimeout(() => {
				chatSpaceRef.current.scrollTop = chatSpaceRef.current.scrollHeight
			}, 100)

			document.getElementById('inputMessage').focus()
		}
	}, [messages])

	useEffect(() => {
		// if loading state is on then show loading message
		if (isLoadingAiMessage && messages) {
			setMessages((prev) => {
				return [
					...prev,
					{
						position: 'left',
						type: 'text',
						title: 'AI',
						text: 'Typing...',
						className: `message-item-left`,
					},
				]
			})
		}
	}, [isLoadingAiMessage])

	const createChatSpace = async () => {
		try {
			setLoading(true)
			const timestamp = moment().unix()
			const uniqueId = `tempCustomer${timestamp}`
			const body = {
				businessId: id,
				customerId: uniqueId,
				messages: [],
				user_name: name,
				user_email: email,
			}
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/createChatSpace`,
				{
					method: 'POST',
					body: JSON.stringify(body),
					headers: {
						'Content-Type': 'application/json',
						// Authorization: userData.tkn,
					},
				}
			)
			localStorage.setItem('tempCustomerId', JSON.stringify(uniqueId))
			setUserInfoModal(false)
			setLoading(false)
		} catch (err) {
			setLoading(false)
			toast.error('Something went wrong while fetching data')
		}
	}

	const fetchExistingMessages = async (tempCustomerId) => {
		try {
			const body = {
				customerId: tempCustomerId,
				receiverId: id,
			}
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/getMessageForUser`,
				{
					method: 'POST',
					body: JSON.stringify(body),
					headers: {
						'Content-Type': 'application/json',
						// Authorization: userData.tkn,
					},
				}
			)
			const data = await res.json()

			const messageList = data?.chatSpace?.messages?.map((item) => {
				return {
					position: tempCustomerId === item?.senderId ? 'right' : ' left',
					type: 'text',
					title: tempCustomerId === item?.senderId ? 'You' : 'AI',
					text: item?.message,
					className: `message-item-${
						tempCustomerId === item?.senderId ? 'right' : ' left'
					}`,
				}
			})

			setMessages(messageList)

			chatSpaceRef.current.scrollTop = chatSpaceRef.current.scrollHeight
		} catch (err) {
			toast.error('Something went wrong while fetching data')
		}
	}

	const createNewMessage = async () => {
		setIsLoadingAiMessage(true)
		const tempCustomerId = JSON.parse(localStorage.getItem('tempCustomerId'))
		try {
			if (inputMessage !== '') {
				// setLoading(true)
				const m = {
					position: 'right',
					type: 'text',
					title: 'You',
					text: inputMessage,
					className: `message-item-right`,
				}

				setMessages([...messages, m])
				setInputMessage('')

				const body = {
					message: {
						senderId: tempCustomerId,
						receiverId: id,
						message: inputMessage,
					},
					customerId: tempCustomerId,
				}
				const res = await fetch(
					`${process.env.REACT_APP_API_URL}/createNewMessage`,
					{
						method: 'POST',
						body: JSON.stringify(body),
						headers: {
							'Content-Type': 'application/json',
							// Authorization: userData.tkn,
						},
					}
				)

				setLoading(false)
			} else {
				setLoading(false)
				toast.warning('No message to send')
			}
		} catch (err) {
			toast.error('Something went wrong while sending message')
		}
	}

	const onSocketOpen = () => {
		setIsConnected(true)
		const tempCustomerId = JSON.parse(localStorage.getItem('tempCustomerId'))

		if (socket.current?.readyState === WebSocket.OPEN && tempCustomerId) {
			socket.current?.send(
				JSON.stringify({ action: 'setUser', name: tempCustomerId })
			)
		}
	}

	const onSocketClose = useCallback(() => {
		setMembers([])
		setIsConnected(false)

		setTimeout(() => {
			onConnect()
		}, 5000)
	}, [])

	const onSocketMessage = (dataStr) => {
		const data = JSON.parse(dataStr)

		if (data.members) {
			setMembers(data.members)
		} else if (data.sendAIResponse) {
			setIsLoadingAiMessage(false)

			setMessages((prev) => {
				return [
					...prev.filter((item) => item.text !== 'Typing...'),
					{
						position: 'left',
						type: 'text',
						title: 'AI',
						text: data.sendAIResponse,
						className: `message-item-left`,
					},
				]
			})
		} else if (data.publicMessage) {
			setMessages((prev) => [
				...prev,
				{
					position: 'left',
					type: 'text',
					title: 'Broadcast',
					text: data.publicMessage,
					className: `message-item-left`,
				},
			])
		} else if (data.privateMessage) {
			setMessages([
				...messages,
				{
					position: 'left',
					type: 'text',
					title: 'AI Response',
					text: data.privateMessage,
					className: `message-item-left`,
				},
			])
		} else if (data.systemMessage) {
			// console.log('messages', messages)
			// setMessages([
			// 	...messages,
			// 	{
			// 		position: 'left',
			// 		type: 'text',
			// 		title: 'System',
			// 		text: data.systemMessage,
			// 		className: `message-item-left`,
			// 	},
			// ])
		}
	}

	const onConnect = () => {
		const tempCustomerId = JSON.parse(localStorage.getItem('tempCustomerId'))

		if (socket.current?.readyState !== WebSocket.OPEN && tempCustomerId) {
			socket.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL)
			socket.current.addEventListener('open', onSocketOpen)
			socket.current.addEventListener('close', onSocketClose)
			socket.current.addEventListener('message', (event) => {
				onSocketMessage(event.data)
			})
		}
	}

	useEffect(() => {
		console.log('useEffect called')

		const tempCustomerId = JSON.parse(localStorage.getItem('tempCustomerId'))
		if (tempCustomerId) {
			fetchExistingMessages(tempCustomerId)
		} else {
			setUserInfoModal(true)
		}

		return () => {
			console.log('useEffect cleanup')
			socket.current?.close()
		}
	}, [])

	useEffect(() => {
		onConnect()
	}, [JSON.parse(localStorage.getItem('tempCustomerId'))])

	// useEffect(() => {
	// 	const intervalId = setInterval(() => {
	// 		const tempCustomerId = JSON.parse(localStorage.getItem('tempCustomerId'))
	// 		console.log('tempCustomerId', tempCustomerId)
	// 		fetchExistingMessages(tempCustomerId)
	// 	}, 20000)
	// 	return () => clearInterval(intervalId)
	// }, [])

	// Function to convert \n to <br/>
	function convertNewlinesToBreaks(text) {
		const doubleBreak = text.replace(/\n\n/g, '\n')
		const splitText = doubleBreak.split('\n')

		// Split the text by line breaks and map through each item and add a <br/> tag

		return splitText.map((item, index) => {
			return (
				<span key={index}>
					{item}
					{
						// If the index is not the last item, add a <br/> tag
						index !== splitText.length - 1 && <br />
					}
				</span>
			)
		})
	}

	// Function to format messages
	function formattedMessages(messages) {
		return messages.map((message) => ({
			...message,
			text: <div>{convertNewlinesToBreaks(message.text)}</div>,
		}))
	}

	return (
		<div className='support'>
			{/* <NavBar selected={"integrations"} /> */}
			{loading && (
				<div className='loader-container common-spinner'>
					{' '}
					<Spinner
						style={{ position: 'relative', zIndex: 100, color: '#4f43be' }}
					>
						Loading...
					</Spinner>{' '}
				</div>
			)}
			<Container>
				<div className='support-chat-wrapper'>
					<div className='header'>
						<h3>Visitor chat</h3>
						{/* <p>last seen 45 minutes ago</p> */}
					</div>
					<div ref={chatSpaceRef} className='support-chat'>
						<MessageList
							className='message-list'
							lockable={true}
							toBottomHeight={'100%'}
							dataSource={formattedMessages(messages) || []}
						/>
					</div>
				</div>
				<div className='chat-footer'>
					<Input
						id='inputMessage'
						placeholder='Type here...'
						value={inputMessage}
						onChange={(e) => {
							setInputMessage(e?.target?.value)
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter' && e.target.value !== '') {
								createNewMessage()
							}
						}}
						multiline={true}
					/>
					{/* <Button text={"Send"} className="primary-btn" onClick={() => createNewMessage()} title="Send"><img src={sendIcon} alt="" /></Button> */}
					<img src={sendIcon} alt='' onClick={() => createNewMessage()} />
				</div>
			</Container>
			<Modal centered className='email-category-modal' isOpen={userInfoModal}>
				<ModalHeader>{'Customer information'}</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup className='mb-3'>
							<Label className='mb-2'>Name</Label>
							<Input
								type='text'
								name='labelName'
								value={name}
								onChange={(e) => {
									setName(e.target.value)
								}}
							/>
						</FormGroup>
						<FormGroup>
							<Label className='mb-2'>Email</Label>
							<Input
								type='text'
								name='description'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value)
								}}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					{/* <Button color="primary" onClick={() => setUserInfoModal(false)}>
            cancel
          </Button> */}
					<Button color='primary' onClick={() => createChatSpace()}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default CustomerChat
