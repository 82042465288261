import React from "react";
import { useEffect, useState } from "react";
import { Card, CardBody, Form, FormGroup, Label, Input, Button, FormText, Spinner } from 'reactstrap';
import alertIcon from "../assets/images/alert-icon.png"
import emailIcon from "../assets/images/email-icon.png"
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from '../components/navbar'
import { getSavedUserData } from "../utils/storage/local";
import { toast } from 'react-toastify';
import { StateContext } from "../utils/context/state_context";


function AgentDescription() {
  const navigate = useNavigate();

  const [businessDescription, setbusinessDescription] = useState('');
  const [agentDescription, setAgentDescription] = useState('');
  const [agentName, setAgentName] = useState('');
  const [loading, setLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState('');

  const { agentData, setAgentData } = React.useContext(StateContext);
  const getAgentDetails = async () => {
    if (agentData?.name) {
      setAgentDescription(agentData?.agentDescription)
      setbusinessDescription(agentData?.businessDescription)
      setAgentName(agentData?.name)
    } else {
      setLoading(true)
    }
    try {
      const userData = getSavedUserData();
      const res = await fetch(`${process.env.REACT_APP_API_URL}/settings/getAgentDetails`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": userData.tkn
        },
      });
      const data = await res.json();
      setAgentDescription(data?.details?.agentDescription)
      setbusinessDescription(data?.details?.businessDescription)
      setAgentName(data?.details?.agentName)
      setAgentData({
        name: data?.details?.agentName,
        businessDescription: data?.details?.businessDescription,
        agentDescription: data?.details?.agentDescription
      })
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
      toast.error("Something went wrong while fetching data");
    }
  }

  useEffect(() => {
    getAgentDetails()
  }, [])

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    if (value.length <= 300) {
      setAgentDescription(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Description cannot exceed 500 characters');
    }
  };

  const handleBusinessDescriptionChange = (event) => {
    const { value } = event.target;
    if (value.length <= 200) {
      setbusinessDescription(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Description cannot exceed 500 characters');
    }
  };

  const handleAgentNameChange = (event) => {
    const { value } = event.target;
    if (value.length <= 50) {
      setAgentName(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Description cannot exceed 500 characters');
    }
  };

  const handleSubmit = async () => {
    try {
      if (agentDescription && businessDescription && agentName) {
        setLoading(true)
        const userData = getSavedUserData()
        console.log("userData", userData)
        const res = await fetch(`${process.env.REACT_APP_API_URL}/settings/saveAgentDescription`, {
          method: "POST",
          body: JSON.stringify({
            agentDescription,
            businessDescription,
            agentName
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        });
        if (res.status == 200) {
          userData.userData.agentDescription = agentDescription
          userData.userData.businessDescription = businessDescription
          localStorage.setItem("userdata", JSON.stringify(userData));
          setLoading(false)
          navigate("/actions");
        } else {
          setLoading(false)
          // navigate("/agent-description");
          navigate("/dashboard");
          toast.error("Something went wrong while saving data");
        }
      } else {
        toast.warning("Please fill all details");
      }
    } catch (error) {
      setLoading(false)
      toast.error("Something went wrong while saving data");
    }
  };

  return (
    <div className="agent-background">
      {/* <NavBar selected={"agentDescription"} /> */}
      <div className="agent-card">
        <Card>
          <CardBody>
            <Form>
              <FormGroup>
                <Label className="mb-2">Host Name</Label>
                <Input
                  id="exampleText"
                  name="text"
                  type="text"
                  value={agentName}
                  onChange={(e) => handleAgentNameChange(e)}
                />
                <FormText>
                  <img src={alertIcon} alt="alert" />
                  Example - Alan, Ashley, Mavrik
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label className="mb-2">Business Name</Label>
                <Input
                  id="exampleText"
                  name="text"
                  type="text"
                  value={businessDescription}
                  onChange={(e) => handleBusinessDescriptionChange(e)}
                />
                <FormText>
                  <img src={alertIcon} alt="alert" />
                  Example - Smile Dental Clinic
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label className="mb-2">Business Description</Label>
                <Input
                  id="exampleText1"
                  name="text"
                  type="textarea"
                  className="business-description"
                  value={agentDescription}
                  onChange={(e) => handleDescriptionChange(e)}
                />
                <FormText>
                  <img src={alertIcon} alt="alert" />
                  Example - Proficiency in handling all kind of dental services
                </FormText>
              </FormGroup>

            </Form>
            {loading && <div className="common-spinner"><Spinner>Loading...</Spinner> </div>}
            <div className="card-bottom-btn">
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </CardBody>
        </Card>

      </div>
    </div>
  );
}

export default AgentDescription;