import React, { useEffect, useState } from "react";
import NavBar from "../components/navbar";
import { toast } from "react-toastify";
import Delete from "../assets/images/trash.png";
import Edit from "../assets/images/blue-edit.png";
import {
  Modal,
  Button,
  ModalFooter,
  ModalHeader,
  Spinner,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { getSavedUserData, getSavedFeatureStatus } from "../utils/storage/local";
import { list } from "firebase/storage";
import AddEditLabelModal from "../components/modals/AddEditLabelModal";

const Labels = () => {
  const [agentName, setAgentName] = useState("");
  const [List, setList] = useState([]);
  const [selectEditData, setSelectEditData] = useState();
  const [selectDeleteData, setSelectDeleteData] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLoader, SetShowloader] = useState(false);
  const [enableLabel, setEnableLabel] = useState(false);

  const userData = getSavedUserData();
  const url = process.env.REACT_APP_API_URL;
  const handleInputChange = (e) => {
    setAgentName(e.target.value);
  };

  const handleSubmit = async () => {
    const apiEndpoint = `${url}/createLabel`;
    const data = {
      name: agentName,
    };
    SetShowloader(true);
    try {
      const res = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: userData.tkn,

          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const response = await res.json();
      if (response?.success?.acknowledged) {
        toast.success(" Added successfully");
        setAgentName("");
        handlegetList();
        SetShowloader(false);
      } else {
        toast.error(response?.details);
        SetShowloader(false);
      }
    } catch (error) {
      console.error("error", error);
      SetShowloader(false);
    }
  };

  const handlegetList = async () => {
    const apiEndpoint = `${url}/listLabel`;
    SetShowloader(true);
    try {
      const res = await fetch(apiEndpoint, {
        method: "GET",
        headers: {
          Authorization: userData.tkn,
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();

      if (response?.data) {
        setShowCreateModal(false);
        setList(response?.data);
        // setEnableLabel(userData?.userData?.featureStatus?.label);
      }
      SetShowloader(false);
    } catch (error) {
      console.error("error", error);
      SetShowloader(false);
    }
  };

  const onClickCreate = () => {
    setSelectEditData();
    setShowCreateModal((prevState) => !prevState);
  };

  const handleEditClick = (data) => {
    setSelectEditData(data);
    setShowCreateModal((prevState) => !prevState);
    // setAgentName(data.label);
  };
  const handleDeleteClick = (data) => {
    setShowDeleteModal(true);
    setSelectDeleteData(data);
  };

  const handleUpdate = async () => {
    const apiEndpoint = `${url}/updateLabel`;
    const payload = {
      labelId: selectEditData?.labelId,
      name: agentName,
    };
    SetShowloader(true);
    try {
      const res = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: userData.tkn,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const response = await res.json();
      if (response?.success) {
        toast.success(" Update successfully");
        handlegetList();
        setAgentName("");
        setSelectEditData("");
        SetShowloader(false);
      }
    } catch (error) {
      console.error("error", error);
      SetShowloader(false);
    }
  };
  const handleDelete = async () => {
    const apiEndpoint = `${url}/removeLabel`;
    const payload = {
      labelId: selectDeleteData?.labelId,
    };
    SetShowloader(true);
    try {
      const res = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: userData.tkn,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const response = await res.json();
      if (response?.success) {
        toast.success("Deleted successfully");
        handlegetList();
        setSelectDeleteData("");
        setShowDeleteModal(false);
        SetShowloader(true);
      }
    } catch (error) {
      console.error("error", error);
      SetShowloader(false);
    }
  };

  const changeFeatureStatus = async() => {
    const apiEndpoint = `${url}/changeFeatureStatus`;
    const data = {
      label: !enableLabel,
    };
    SetShowloader(true);
    try {
      const res = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: userData.tkn,

          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const response = await res.json();
      console.log("response", response)
      if (response?.success) {
        toast.success(" Added successfully");
        const copyStatusData = getSavedFeatureStatus()
        copyStatusData.label = !enableLabel
        localStorage.setItem("featureStatus", JSON.stringify(copyStatusData));
        SetShowloader(false);
      } else {
        toast.error(response?.details);
        
        SetShowloader(false);
      }
    } catch (error) {
      console.error("error", error);
      SetShowloader(false);
    }
  }

  const renderTableBody = () => {
    if (List?.length > 0) {
      return List?.map((tableData, index) => {
        const lastUpdatedAt = tableData?.lastUpdatedAt?.split("T")[0];
        return (
          <tr>
            <td>{tableData?.label}</td>
            <td>{tableData?.labelDescription}</td>
            <td>{lastUpdatedAt}</td>
            <td>
              <div className="icon">
                <img
                  src={Edit}
                  className="img1"
                  alt="edit icon"
                  onClick={() => handleEditClick(tableData)}
                />
                <img
                  src={Delete}
                  className="img2"
                  alt="delete icon"
                  onClick={() => handleDeleteClick(tableData)}
                />
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  useEffect(() => {
    const stausData = getSavedFeatureStatus()
    setEnableLabel(stausData?.label)
    handlegetList();
  }, []);
  return (
    <div className="agent-background">
      <NavBar selected={"labels"} />
      {showLoader && (
        <div className="loader-container common-spinner">
          <Spinner
            style={{ position: "relative", zIndex: 100, color: "#4f43be" }}
          >
            Loading...
          </Spinner>
        </div>
      )}
      <section className="labels-wrapper">
        {/* <div className="border p-4 rounded"> */}
        <div className="labels-form-wrapper">
          {/* <div className="labels-form-heading">
                  <h4>Create Label </h4>
                </div> */}
          <div className="labels-form-input">
            {/* <p>Front Dest Agent Name </p> */}
            <div className="form-container">
              {/* <input
                      name="text"
                      type="text"
                      placeholder={agentName}
                      value={agentName}
                      onChange={handleInputChange}
                    /> */}
              <h4 className="label">Email Label </h4>
              {/* <FormGroup switch>
                <Input
                  type="switch"
                  checked={enableLabel}
                  onClick={() => {
                    changeFeatureStatus()
                    setEnableLabel(!enableLabel);
                  }}
                />
                <Label check>Enable Label</Label>
              </FormGroup> */}
              <button className="ms-3" onClick={() => onClickCreate()}>
                Create
              </button>
            </div>
          </div>
        </div>
        <div className="labels-mail-wrapper">
          <Table responsive hover size="sm" responsivestriped className="mb-0">
            <thead>
              <tr>
                <th>Label Name</th>
                <th>Description</th>
                <th>Last Updated At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <>
                {List?.length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr className="no-data">
                    <td colspan="12">No Labels as of now</td>
                  </tr>
                )}
                {/* {List &&
                      List?.map((item, index) => (
                        <div className="mailbox" key={item._id}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p>
                              {" "}
                              <span>{`${index + 1}.`}</span>
                              {item.label}
                            </p>
                          </div>
                          <div className="icon">
                            <img
                              src={Edit}
                              className="img"
                              alt="edit icon"
                              onClick={() => handleEditClick(item)}
                            />
                            <img
                              src={Delete}
                              className="img2"
                              alt="delete icon"
                              onClick={() => handleDeleteClick(item)}
                            />
                          </div>
                        </div>
                      ))} */}
              </>
            </tbody>
          </Table>
          {/* <div className="labels-mail-box">
                  <div className="row-bo">
                    {List &&
                      List?.map((item, index) => (
                        <div className="mailbox" key={item._id}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <p> <span>{`${index + 1}.`}</span>{item.label}</p>
                          </div>
                          <div className="icon">
                            <img
                              src={Edit}
                              className="img"
                              alt="edit icon"
                              onClick={() => handleEditClick(item)}
                            />
                            <img
                              src={Delete}
                              className="img2"
                              alt="delete icon"
                              onClick={() => handleDeleteClick(item)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div> */}
        </div>
        {/* </div> */}
      </section>
      <AddEditLabelModal
        isOpen={showCreateModal}
        handleAddCategory={onClickCreate}
        selectedCategoryAccordian=""
        selectEditData={selectEditData}
        url={url}
        SetShowloader={SetShowloader}
        handlegetList={handlegetList}
        userData={userData}
      />
      <Modal centered className="email-category-modal" isOpen={showDeleteModal}>
        <ModalHeader>{`Are you sure you want to delete ${selectDeleteData?.label}?`}</ModalHeader>
        <ModalFooter>
          <Button color="secondary" outline onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Labels;
