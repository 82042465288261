export const getSavedUserData = () => {
  return JSON.parse(localStorage.getItem("userdata"));
};

export const getSavedFeatureStatus = () => {
  return JSON.parse(localStorage.getItem("featureStatus"));
};

export const  checkIfCalIntegrated = () => {
  const data =  JSON.parse(localStorage.getItem("userdata"));
  return data?.userData?.isCalIntegrated
};
