import React, { useEffect, useState } from "react"
import {
	Button,
	Table,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	Col,
	Row,
	Container,
	Spinner,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Pagination,
	PaginationItem,
	PaginationLink,
} from "reactstrap"
import alertIcon from "../assets/images/alert-icon.png"
import emailIcon from "../assets/images/email-icon.png"
import NavBar from "../components/navbar"
import { getSavedUserData } from "../utils/storage/local"
import { toast } from "react-toastify"
import { StateContext } from "../utils/context/state_context"
import moment from "moment"
import mail from "../assets/images/mail.png"
import checked from "../assets/images/checked.png"
import percentage from "../assets/images/percent.png"
import processTime from "../assets/images/processing-time.png"
import { useNavigate } from "react-router-dom"

const staticData = [
	{
		lastUpdatedAt: "2024-02-09T07:21:43.970Z",
		from: "ashutosh sharma <ashutosh@innow8apps.com>",
		category: "New Appointment",
		outcome: "inprogess",
		notes: {
			note: "Client: subject: Dental Appointment\nbody:  \nHey I'm looking to book an appointment\r\n\r\nRegards\r\nAshutosh\r\n\n\nMe:   \nPlease provide following information: \r\n\r\nContact Number\r\n\r\nEmail\r\n\r\nCheckup type \r\n\r\nThank You.\r\n\n\nMe:   \nPlease provide following information: \r\n\r\nContact Number\r\n\r\nEmail\r\n\r\nCheckup type \r\n\r\nThank You.\r\n\n\nClient:   \nHere is my contact number 9585858958\r\n\r\n\nMe:   \nPlease provide following information: \r\n\r\nCustomer Name\r\n\r\nEmail\r\n\r\nCheckup type \r\n\r\nThank You.\r\n\n\n",
			variable: {
				category: "New Appointment",
				"Customer Name": "",
				"Contact Number": "9585858958",
				Email: "",
				"Checkup type": "",
				message: "Success",
			},
		},
	},
]

function EmailDetails() {
	const [tableData, setTableData] = useState([])
	const [notes, setNotes] = useState("")
	const [showNotes, setShowNotes] = useState(false)
	const [emailAnalysis, setEmailAnalysis] = useState({})
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [canvasLoader, setCanvasLoader] = useState(false)
	const { dashboardData, setDashboardData } = React.useContext(StateContext)
	const [subscriptionEndedModal, setSubscriptionEndedModal] = useState(false)
	const navigate = useNavigate()

	console.log("dashboardData", dashboardData)
	useEffect(() => {
		getEmailDetails()
	}, [page])

	const getEmailDetails = async () => {
		setLoading(true)
		if (dashboardData?.tableData?.length > 0) {
			setTableData(dashboardData?.tableData)
			setEmailAnalysis(dashboardData?.analysisData)
		} else {
			setLoading(true)
		}
		try {
			const userData = getSavedUserData()
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/emails/getList?page=${page}&limit=9`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: userData.tkn,
					},
				}
			)
			if (res) {
				const data = await res.json()
				console.log("analysisData", data)
				const analysisData = {}
				const inprogressEmails = data?.emails.map((item) => {
					if (item?.outcome === "inprogress") {
						return item
					}
				})
				const successfullyHandledEmails = data?.emails.filter((item) => {
					if (item?.outcome === "success") {
						return true
					}
				})

				analysisData.totalEmails = data?.emails.length
				analysisData.inprogressEmails = inprogressEmails?.length
				analysisData.successfullyHandledEmails =
					successfullyHandledEmails?.length
				analysisData.successPercentage = (
					(analysisData.successfullyHandledEmails / data?.emails.length) *
					100
				).toFixed(1)
				setEmailAnalysis(analysisData)
				const tableData = data?.emails?.map((emailItem, index) => {
					return {
						lastUpdatedAt: emailItem?.lastUpdatedAt,
						from: emailItem?.from,
						category: emailItem?.Category,
						outcome: emailItem?.outcome,
						emailLabel: emailItem?.emailLabel,
						variables: emailItem?.variables,
						notes: { note: emailItem?.note, variable: emailItem?.variables },
						id: emailItem.id,
					}
				})
				setTableData(tableData)
				setDashboardData({ tableData, analysisData })
				setTotalPages(data?.pagination?.totalPages)
				setLoading(false)
			} else {
				setTableData([])
				setLoading(false)
			}
		} catch (err) {
			console.error(err)
			setLoading(false)
			toast.error("Something went wrong while fetching data")
		}
	}

	const handleSeeNotes = (id) => {
		console.log("id", id)
		setCanvasLoader(true)
		setNotes({ note: "", variable: {} })
		try {
			const userData = getSavedUserData()
			fetch(`${process.env.REACT_APP_API_URL}/emails/getDetail`, {
				method: "POST",
				body: JSON.stringify({
					id: id,
					userId: userData?.userData?.userId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: userData.tkn,
				},
			})
				.then((res) => res.json())
				.then((result) => {
					if (result.success) {
						setNotes({
							note: result?.email?.note,
							variable: result?.email?.variables,
						})
					}
					console.log("result: ", result)
					setCanvasLoader(false)
				})
				.catch((err) => {
					console.error(err)
					setCanvasLoader(false)
					toast.error("Something went wrong while fetching data")
				})
		} catch (err) {
			console.error(err)
			setCanvasLoader(false)
			toast.error("Something went wrong while fetching data")
		}

		setShowNotes(true)
	}

	const notesCanvasHandler = () => {
		setShowNotes(!showNotes)
	}

	const handleSubscriptionEndedModal = () => {
		setSubscriptionEndedModal(!subscriptionEndedModal)
	}

	const gotoSubscriptionPage = () => {
		navigate("/subscription")
	}

	const renderTableBody = () => {
		if (loading && tableData?.length === 0) {
			return <Spinner color="dark">Loading...</Spinner>
		} else if (tableData?.length > 0) {
			return tableData?.map((tableItem, index) => {
				console.log("tableItem", tableItem)
				const date = tableItem?.lastUpdatedAt
					? moment(tableItem?.lastUpdatedAt).isValid()
						? moment(tableItem?.lastUpdatedAt).format("MMMM Do YYYY, hh:mm:ssa")
						: ""
					: ""
				const category =
					tableItem?.category && tableItem?.category !== "N/A"
						? tableItem?.category
						: tableItem?.variables?.subCategory
						? tableItem?.variables?.subCategory
						: "N/A"
				return (
					<tr>
						<td>{tableItem?.from}</td>
						<td>{date}</td>
						<td>{category}</td>
						<td>{tableItem?.emailLabel || "N/A"}</td>
						<td>{tableItem?.outcome}</td>
						<td>
							<Button onClick={() => handleSeeNotes(tableItem?.id)}>
								See Notes
							</Button>
						</td>
					</tr>
				)
			})
		} else {
			return <div>No data to show</div>
		}
	}

	const handleNextPage = () => {
		setPage((prev) => prev + 1)
	}

	const handlePreviousPage = () => {
		setPage((prev) => prev - 1)
	}

	return (
		<>
			<NavBar selected={"dashboard"} />
			<div>
				<div className="dashboard-card">
					{loading && (
						<div className="common-spinner">
							<Spinner color="dark">Loading...</Spinner>{" "}
						</div>
					)}
					<div className="cards-list">
						<h4>Your Account Insights</h4>
						<p>View your front desk AI performance analytics here.</p>
						<Row className="card-container">
							<Col>
								<div className="card-box light-purple">
									<div>
										<h4>Emails</h4>
										<h3>{emailAnalysis?.totalEmails || 0}</h3>
									</div>
									<div>
										<span className="status-icon">
											<img src={mail} alt="mail" />
										</span>
									</div>
								</div>
							</Col>
							<Col>
								<div className="card-box light-green">
									<div>
										<h4>In Progress</h4>
										<h3>{emailAnalysis?.inprogressEmails || 0}</h3>
									</div>
									<div>
										<span className="status-icon">
											<img src={processTime} alt="processTime" />
										</span>
									</div>
								</div>
							</Col>
							<Col>
								<div className="card-box light-orange">
									<div>
										<h4>Successfully Handled</h4>
										<h3>{emailAnalysis?.successfullyHandledEmails || 0}</h3>
									</div>
									<div>
										<span className="status-icon">
											<img src={checked} alt="checked" />
										</span>
									</div>
								</div>
							</Col>
							<Col>
								<div className="card-box light-yellow">
									<div>
										<h4>Success Percentage</h4>
										<h3>
											{!isNaN(emailAnalysis?.successPercentage)
												? emailAnalysis?.successPercentage
												: 0}
											%
										</h3>
									</div>
									<div>
										<span className="status-icon">
											<img src={percentage} alt="percentage" />
										</span>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<Table responsive hover size="sm" responsivestriped className="mb-0">
						<thead>
							<tr>
								<th>From</th>
								<th>Last updated at</th>
								<th>Category</th>
								<th>Label</th>
								<th>Outcome</th>
								<th>Notes</th>
							</tr>
						</thead>
						<tbody>
							<>
								{tableData?.length > 0 ? (
									renderTableBody()
								) : (
									<tr className="no-data">
										<td colspan="12">No emails as of now</td>
									</tr>
								)}
							</>
						</tbody>
					</Table>
					<Pagination
						className="pagination-nav"
						aria-label="Page navigation example"
						size="sm"
					>
						<PaginationItem disabled={page === 1}>
							<PaginationLink previous onClick={handlePreviousPage}>
								<i class="fa-solid fa-angles-left"></i> Previous
							</PaginationLink>
						</PaginationItem>

						<PaginationItem disabled={page === totalPages}>
							<PaginationLink next onClick={handleNextPage}>
								Next <i class="fa-solid fa-angles-right"></i>
							</PaginationLink>
						</PaginationItem>
					</Pagination>
				</div>
				<Offcanvas
					className="email-thread-modal"
					isOpen={showNotes}
					direction="end"
					toggle={notesCanvasHandler}
				>
					<OffcanvasHeader toggle={notesCanvasHandler}>
						<h4>Email threads</h4>
					</OffcanvasHeader>
					<OffcanvasBody>
						{canvasLoader && (
							<div className="loader-container">
								{" "}
								<Spinner
									style={{
										position: "relative",
										zIndex: 100,
										color: "#4f43be",
									}}
								>
									Loading...
								</Spinner>{" "}
							</div>
						)}
						<div className="content-details">
							<h5 className="title">Prospect Details</h5>
							{notes?.note &&
								console.log("Prospect", Object.keys(notes?.variable))}
							{notes?.note &&
								Object.keys(notes?.variable).map((keyItem) => {
									if (
										keyItem == "bookingOptions" ||
										keyItem == "bookedOptions"
									) {
										return
									}
									return (
										<div style={{ display: "flex" }} className="content-detail">
											<span className="item">{keyItem}:</span>
											<span className="value">{notes?.variable[keyItem]}</span>
										</div>
									)
								})}
						</div>
						<div className="content-details">
							<h5 className="title">Email notes</h5>
							<textarea className="note" value={notes && notes?.note}>
								{" "}
							</textarea>
						</div>
					</OffcanvasBody>
				</Offcanvas>
			</div>
		</>
	)
}

export default EmailDetails
