import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AgentDescription from "../pages/agent_description";
import Questionnaire from "../pages/questionnaire";
import Login from "../pages/login";
import EmailDetails from "../pages/email_details";
import NavigationHOC from "../HOCs/Navigation";
import Integrations from "../pages/integrations";
import Bookings from "../pages/bookings";
import KnowledgeBase from "../pages/knowledge_base";
import Subscription from "../pages/subscription";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentFailure from "../pages/payment_failure";
import PaymentSuccess from "../pages/payment_success";
import Labels from "../pages/Labels";
import Support from "../pages/Support";
import CustomerChat from '../pages/customer_chat'
import Chat from '../pages/chat'

export default NavigationHOC(({ redirect }) => {
  const navigate = useNavigate();

  const location = useLocation();
  console.log("NavigationHOC useeffect",location.pathname.includes("customer-chat"));

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    if (!userData && !location.pathname.includes("customer-chat")) {
      navigate("/");
    }
  });

  return (
    <Routes>
      <Route path={"/"} element={<Login />} key={"/"} />
      {/* <Route
        path={"/agent-description"}
        element={<AgentDescription />}
        key={"/home"}
      /> */}
      <Route path={"/actions"} element={<Questionnaire />} key={"/auth"} />
      <Route
        path={"/dashboard"}
        element={<EmailDetails />}
        key={"/dashboard"}
      />
      <Route
        path={"/settings"}
        element={<Integrations />}
        key={"/settings"}
      />
      <Route path={"/bookings"} element={<Bookings />} key={"/bookings"} />
      <Route
        path={"/knowledge-base"}
        element={<KnowledgeBase />}
        key={"/knowledge-base"}
      />
      <Route
        path={"/subscription"}
        element={<Subscription />}
        key={"/subscription"}
      />
      <Route
        path={"/payment-success"}
        element={<PaymentSuccess />}
        key={"/payment-success"}
      />
      <Route
        path={"/payment-failed"}
        element={<PaymentFailure />}
        key={"/payment-failed"}
      />
      <Route path={"/labels"} element={<Labels />} key={"/labels"} />
      <Route path={"/support"} element={<Support />} key={"/labels"} />
      <Route
        path={"/chat"}
        element={<Chat/>}
        key={"/chat"}
      />
      <Route
        path={"/customer-chat/:id"}
        element={<CustomerChat />}
        key={"/customer-chat"}
      />

      <Route path="/*" element={<div>Page Not Found</div>} key="/*" />
    </Routes>
  );
});
