import React from 'react';
import { useNavigate } from "react-router-dom";

const PaymentFailure = () => {
    const navigate = useNavigate();

    const handleReturnHome = () => {
        navigate('/');
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.errorText}>
                Oops! Looks like something went wrong with your payment.
            </h2>
            <p style={styles.infoText}>
                Don't worry, our team of tech wizards is on it. In the meantime, why not treat yourself to a cup of coffee while we sort this out?
            </p>
            <button style={styles.button} onClick={handleReturnHome}>Return to Home</button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh'
    },
    errorText: {
        color: 'red',
        fontSize: 30,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    infoText: {
        textAlign: 'center',
        marginVertical: 30,
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#f50e02',
        color: 'white',
        border: 'none',
        borderRadius: '50px',
        cursor: 'pointer',
    },
};

export default PaymentFailure;



