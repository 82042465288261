import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Spinner,
  FormGroup,
  Button,
  Label,
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane,
  Card,
  CardTitle,
  CardText,
  ModalHeader,
  ModalFooter,
  Modal,
  ModalBody,
  Form,
} from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavBar from "../components/navbar";
import moment from "moment";
import { StateContext } from "../utils/context/state_context";
import { MessageList, Input } from "react-chat-elements";
import icon from "../assets/images/copy-link.png";
import { getSavedUserData } from "../utils/storage/local";
import { toast } from "react-toastify";
import "react-chat-elements/dist/main.css";

function Chat() {
  const userData = getSavedUserData();
  console.log("chat------->", userData);
  return (
    <div className="agent-background">
      <NavBar selected={"chat"} />
      <section className="chat-link">
        <h2>Support link for your customers</h2>
        <div className="d-flex align-items-center">
          <p className="mb-0">{`${process.env.REACT_APP_FRONTEND_URL}/customer-chat/${userData?.userData?.userId}`}</p>
          <img src={icon} alt="icon" onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_FRONTEND_URL}/customer-chat/${userData?.userData?.userId}`
            );
            toast.success("Successfully copied")
          }
          } />
        </div>
      </section>
      {/* {loading && <div className='loader-container common-spinner'> <Spinner style={{ position: "relative", zIndex: 100, color: "#4f43be" }} >Loading...</Spinner>     </div>} */}
    </div>
  );
}

export default Chat;
