import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
// import CustomButton from "../../utils/CustomButton";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { getSavedUserData } from "../../utils/storage/local";
import { useNavigate } from "react-router-dom";


const CheckoutForm = (props) => {
  const {
    isCheckoutModalOpen,
    setIsCheckoutModalOpen,
    inputLabel,
    setIsPaymentModalOpen,
    setAmount,
    invoiceUs,
    clientSecret,
    stripePromise,
    selectedPlan,
    addInProgressTransaction
  } = props;

  console.log("CheckoutForm")
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    console.log("handleSubmit", e)
    e.preventDefault();
   const inProgressTransaction = await addInProgressTransaction()
   console.log("inProgressTransaction", inProgressTransaction)
   const transactionDbId = inProgressTransaction?.transaction?.insertedId
    if (!stripe || !elements || !isCardComplete) {
      return;
    }

    setIsProcessing(true);
    await stripe
      .confirmPayment({
        elements,
        redirect: "if_required",
      })
      .then(async (res) => {
        let userData = getSavedUserData();
        if (res?.paymentIntent) {
          const { status, id, amount } = res?.paymentIntent;
          
          // console.log("getSavedUserData", userData)
          const body = {
            status,
            transactionId: id,
            amount,
            planId: selectedPlan?._id,
            idToEdit: transactionDbId
          };
          const transaction = await fetch(
            `${process.env.REACT_APP_API_URL}/saveTransactionRecord`,
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
                "Authorization": userData.tkn
              },
            }
          );
          userData.userData.isSubscriptionEnded = false
          console.log("localStorage", userData)
          localStorage.setItem("userdata", JSON.stringify(userData));

          setIsPaymentModalOpen(false)
          toast.success("Payment Successful");
          navigate("/dashboard")
        }

        if (res?.error) {
          console.log("payerror", res?.error)
          setAmount(0);
          const { status, id, amount } = res?.error?.payment_intent;
          const body = {
            status,
            transactionId: id,
            amount,
            planId: selectedPlan?._id,
            idToEdit: transactionDbId
          };
          const transaction = await fetch(
            `${process.env.REACT_APP_API_URL}/saveTransactionRecord`,
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
                "Authorization": userData.tkn
              },
            }
          );
          // handle payment records fail
          setIsProcessing(false);
          if (
            res?.error?.type === "card_error" ||
            res?.error?.type === "validation_error"
          ) {
            toast.error(res?.error?.message);
          } else {
            toast.error("An unexpected error occured.");
          }
          setIsProcessing(false);
        }
      });
  };

  useEffect(() => {
    console.log("useEffect", elements)
    if (!elements || !stripe) {
      return
    }
    const cardElement = elements?.getElement(PaymentElement);

    const handleChange = (event) => {
      setIsCardComplete(event?.complete);
    };

    cardElement?.on("change", handleChange);

    return () => {
      cardElement?.off("change", handleChange);
    };
  }, [elements, stripe]);

  return (
    <form id="payment-form">
      {isCheckoutModalOpen && (
        <>
          {clientSecret && stripePromise && <PaymentElement id="payment-element" />}
          {elements && <Button onClick={handleSubmit}>Pay now</Button>}
        </>
      )}
    </form>
  );
};

export default CheckoutForm;
 {/* loading={isProcessing}
              style={{ padding: "20px 0px 20px 0px" }}
              key={"Pay Now"}
              buttonTitle={"Pay Now"}
              onClick={handleSubmit}
              disabled={!isCardComplete}
              className="add-button-container" */}