import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import NavBar from '../components/navbar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { toast } from 'react-toastify';
import { getSavedUserData } from '../utils/storage/local';
import deleteIcon from "../assets/images/delete-icon.png";
import editIcon from "../assets/images/edit-icon.png";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, CardHeader, Spinner, UncontrolledTooltip, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { StateContext } from '../utils/context/state_context';

const EVENTS = [
  {
    "id": 1408215,
    "title": "30 Min Meeting between Ashutosh Sharma and constant",
    "start": "2024-03-06T08:30:00.000Z",
    "end": "2024-03-06T09:00:00.000Z",
    "entryId": "65df279cae4eeb36d45923c7",
    "tooltipId": "tooltip-0-87"
  },
  {
    "id": 1417220,
    "title": "30 Min Meeting between Ashutosh Sharma and constant",
    "start": "2024-03-06T07:00:00.000Z",
    "end": "2024-03-06T07:30:00.000Z",
    "entryId": "65e066876827108370b8b00b",
    "tooltipId": "tooltip-1-93"
  },
  {
    "id": 1417220,
    "title": "30 Min Meeting between Ashutosh Sharma and constant",
    "start": "2024-03-06T08:00:00.000Z",
    "end": "2024-03-06T08:30:00.000Z",
    "entryId": "65e0672d3657c26b0deac42e",
    "tooltipId": "tooltip-2-51"
  },
  {
    "id": 1417220,
    "title": "30 Min Meeting between Ashutosh Sharma and constant",
    "start": "2024-03-07T09:00:00.000Z",
    "end": "2024-03-07T09:30:00.000Z",
    "entryId": "65e067383657c26b0deac42f",
    "tooltipId": "tooltip-3-91"
  }
]

function Bookings() {
  const [bookings, setBookings] = useState(EVENTS)
  const [editBookingModal, setEditBookingModal] = useState(false)
  const [bookingToEdit, setBookingToEdit] = useState({})
  const [canvasloading, setCanvasLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showMeetingDetails, setShowMeetingDetails] = useState(false)
  const [selectedDateData, setSelectedDateData] = useState([])
  const { bookingsData, setBookingsData } = React.useContext(StateContext);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [bookingToDelete, setBookingToDelete]= useState({})

  const navigate = useNavigate();
  const localizer = momentLocalizer(moment)

  const getBookings = async (checkPrevData) => {
    if (bookingsData.length > 0 && checkPrevData) {
      setBookings(bookingsData)
    } else {
      setLoading(true)
    }
    try {
      const userData = getSavedUserData();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/bookings/getList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn,
          },
        }
      );
      const boodkingData = await res.json()
      const data = boodkingData?.bookings.map((bookingItem, index) => {
        return {
          id: bookingItem?.bookingData?.id,
          title: bookingItem?.bookingData?.title,
          start: moment(bookingItem?.bookingData?.startTime),
          end: moment(bookingItem?.bookingData?.endTime),
          entryId: bookingItem?._id,
          tooltipId: `tooltip-${index}-${Math.floor(Math.random() * 100) + 1}`
        };
      })
      setBookings(data)
      setBookingsData(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error("Something went wrong while fetching data");
    }
  }

  useEffect(() => {
    getBookings(true)
  }, [])

  const handleBookingEdit = (booking) => {
    setEditBookingModal(!editBookingModal)
    setBookingToEdit(booking)
  }
  const handleBookingDelete = async () => {
    setDeleteConfirmationModal(false)
    setCanvasLoading(true)
    try {
      const userData = getSavedUserData();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/bookings/delete`,

        {
          method: "POST",
          body: JSON.stringify({
            bookingId: bookingToDelete?.id,
            id: bookingToDelete?.entryId
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn,
          },
        }
      );
      setShowMeetingDetails(!showMeetingDetails)
      setBookingToDelete({})
      getBookings(false)
      setCanvasLoading(false)
    } catch (err) {
      setCanvasLoading(false)
      setBookingToDelete({})
      toast.error("Something went wrong while saving data");
    }
  }

  const handleSaveBooking = async () => {
    try {
      const userData = getSavedUserData();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/bookings/edit`,

        {
          method: "POST",
          body: JSON.stringify({
            bookingId: bookingToEdit?.id,
            bookingData: {
              start: moment(bookingToEdit?.start).format("YYYY-MM-DDTHH:mm"),
              end: moment(bookingToEdit?.end).format("YYYY-MM-DDTHH:mm"),
              title: bookingToEdit?.title
            }
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn,
          },
        }
      );
      const resData = res.json()
      if (resData?.success) {
        setBookingToEdit({})
      }
    } catch (err) {
      toast.error("Something went wrong while saving data");
    }
  }
  const renderEventList = () => {
    return bookings?.map((item, index) => {
      return (
        <div className="list-details" key={index} >
          <div className="list-details-card" id={item?.tooltipId}>
            <h3>title</h3>
            <p>{item?.title}</p>
            <UncontrolledTooltip
              target={item?.tooltipId}
              placement="top"
            >
              {item?.title}
            </UncontrolledTooltip>
          </div>
          <div className="list-details-card">
            <h3>Start</h3>
            <p>{moment(item?.start).format("DD/MM/YYYY")}</p>
          </div>
          <div className="list-details-card">
            <h3>end</h3>
            <p>{moment(item?.end).format("DD/MM/YYYY")}</p>
          </div>
          <div className="accordian-icons">
            {/* <img
              src={editIcon}
              alt="edit-icon"
              onClick={() => handleBookingEdit(item, index, item)}
            /> */}
            <img
              src={deleteIcon}
              alt="delete-icon"
              onClick={() => handleBookingDelete(item, index)}
            />
          </div>
        </div>
      );
    });
  };

  const meetingDetailsCanvasHandler = () => {
    setShowMeetingDetails(!showMeetingDetails)
  }

  const renderMeetingDetailCard = () => {
    return selectedDateData?.map((bookItem, index) => {
      const duration = moment.duration(bookItem?.end?.diff(bookItem?.start)) || 30
      const hours = duration.hours() + " hour ";
      const minutes = duration.minutes();
      return (
        <div className='meeting-schedule-card'>
          <div className='heading'>
            <h4>{bookItem?.title}</h4>
            <img
              src={deleteIcon}
              alt="delete-icon"
              onClick={() => handleDeleteMeeting(bookItem, index)}
            />
          </div>
          <div className='heading align-items-center'>
            <h5>When</h5>
            <p>{`${moment(bookItem?.start).format("DD/MM/YYYY HH:mm")} to ${moment(bookItem?.end).format("DD/MM/YYYY HH:mm")}`}</p>
          </div>
          <div className='heading align-items-center mb-0'>
            <h6>{"Duration"}</h6>
            <p>{hours > 0 && hours} {minutes + " mins"}</p>
          </div>
        </div>
      )
    })
  }

  const handleCalenderClick = (value) => {
    console.log("handleCalenderClick", value)
    meetingDetailsCanvasHandler()
    const eventsCopy = bookings.slice()
    const filteredData = eventsCopy.filter((eventItem) => moment(eventItem.start).format('YYYY/MM/DD') == moment(value?.start).format('YYYY/MM/DD'))
    console.log("filteredData", filteredData)
    setSelectedDateData(filteredData)
    meetingDetailsCanvasHandler()
  }
  const handleDeleteConfirmationModal = (booking) => {
    setDeleteConfirmationModal(!deleteConfirmationModal);
    setBookingToDelete({})
  };

  const handleDeleteMeeting = (booking)=> {
    setBookingToDelete(booking)
    setDeleteConfirmationModal(true)
  }

  return (
    <div className="agent-background">
      <NavBar selected={"bookings"} />
      <div className="booking-container">
        <div className="list-calender-container">
          {loading && <div className='loader-container common-spinner'> <Spinner style={{ position: "relative", zIndex: 100, color: "#4f43be" }} >Loading...</Spinner>     </div>}
          {/* <div>
            <Card className="booking-list-card">
              <CardHeader>Bookings</CardHeader>

              <CardBody>
                <div className="list-details-container">
                    {loading && <div className='loader-container'> <Spinner style={{ position: "relative", zIndex: 100, color:"#4f43be" }} >Loading...</Spinner>     </div>}
                    {bookings.length === 0 && !loading ? <p className='no-booking-data'>No bookings</p> : renderEventList()}
                  </div>
              </CardBody>
            </Card>
          </div> */}
          <Calendar
            selectable={true}
            localizer={localizer}
            events={bookings || []}
            startAccessor={(event) => { return moment(event.start).toDate() }}
            endAccessor={(event) => { return moment(event.end).toDate() }}
            style={{ height: 700, width: "80%", backgroundColor: "white" }}
            returnValue={"range"}
            onSelectEvent={handleCalenderClick}
          />
        </div>
      </div>
      <Modal
        centered
        className="email-category-modal"
        isOpen={editBookingModal}
        toggle={handleBookingEdit}
      >
        <ModalHeader toggle={handleBookingEdit}>Add Action</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-3">
              <Label className="mb-2">Title</Label>
              <Input
                type="text"
                value={bookingToEdit?.title}
                onChange={(event) => {
                  setBookingToEdit({
                    ...bookingToEdit,
                    title: event.target.value,
                  });
                }}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="mb-2">Start Time</Label>
              <Input
                type="datetime-local"
                value={moment(bookingToEdit?.start).format("YYYY-MM-DDTHH:mm")}
                onChange={(event) => {
                  setBookingToEdit({
                    ...bookingToEdit,
                    start: moment(event.target.value)?._i,
                  });
                }}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="mb-2">End Time</Label>
              <Input
                type="datetime-local"
                value={moment(bookingToEdit?.end).format("YYYY-MM-DDTHH:mm")}
                onChange={(event) => {
                  setBookingToEdit({
                    ...bookingToEdit,
                    end: moment(event.target.value)?._i,
                  });
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveBooking}>
            Save
          </Button>
        </ModalFooter>
      </Modal>

      <Offcanvas className="email-thread-modal" isOpen={showMeetingDetails} direction='end' toggle={meetingDetailsCanvasHandler}>
        <OffcanvasHeader toggle={meetingDetailsCanvasHandler}>
          <h5>Meeting schedules</h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          {canvasloading && <div className='loader-container'> <Spinner style={{ position: "relative", zIndex: 100, color: "#4f43be" }} >Loading...</Spinner>     </div>}
          {renderMeetingDetailCard()}
        </OffcanvasBody>
      </Offcanvas>

      <Modal
        centered
        className="email-category-modal integrate-modal"
        isOpen={deleteConfirmationModal}
        toggle={handleDeleteConfirmationModal}
      >
        <ModalHeader toggle={handleDeleteConfirmationModal}>Delete confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to cancel this meeting?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={handleDeleteConfirmationModal}>
            No
          </Button>
          <Button color="primary" onClick={handleBookingDelete}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Bookings;