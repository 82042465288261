import { useEffect, useState } from "react";
import { Card, CardBody, Form, FormGroup, Label, Input, Button, FormText, Spinner, Container, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import alertIcon from "../assets/images/alert-icon.png"
import emailIcon from "../assets/images/email-icon.png"
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from '../components/navbar'
import { getSavedUserData } from "../utils/storage/local";
import { toast } from 'react-toastify';
import AgentDescription from "./agent_description";


function Integrations() {
  const navigate = useNavigate();

  const [calApiKey, setCalApiKey] = useState('');
  const [calEventTypeId, setCalEventTypeId] = useState('');
  const [calUsername, setCalUsername] = useState('');
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(1);
  const [webhookModal, setWebhookModalModal] = useState(false);

  const userData = getSavedUserData();
  const [url, setUrl] = useState(userData.userData.webHookURL);

  const getAgentDetails = async () => {
    setLoading(true)
    try {
      const userData = getSavedUserData();
      const res = await fetch(`${process.env.REACT_APP_API_URL}/cal/getCalInfo`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": userData.tkn
        },
      });
      const data = await res.json();
      console.log("data", data)
      setCalApiKey(data?.details?.calApiKey)
      setCalEventTypeId(data?.details?.calEventTypeId)
      setCalUsername(data?.details?.calUsername)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
      toast.error("Something went wrong while fetching data");
    }
  }

  useEffect(() => {
    getAgentDetails()
  }, [])

  const handleCalEventTypeChange = (event) => {
    const { value } = event.target;
    setCalEventTypeId(value)
  };

  const handleCalApiKeyChange = (event) => {
    const { value } = event.target;
    setCalApiKey(value)
  };

  const handleCalUsernameChange = (event) => {
    const { value } = event.target;
    setCalUsername(value)
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (calApiKey && calEventTypeId) {
        const userData = getSavedUserData()
        console.log("userData", userData)
        const res = await fetch(`${process.env.REACT_APP_API_URL}/cal/saveCalInfo`, {
          method: "POST",
          body: JSON.stringify({
            calApiKey,
            calEventTypeId,
            calUsername
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        });
        if (res.status == 200) {
          userData.userData.calApiKey = calApiKey
          userData.userData.calEventTypeId = calEventTypeId
          userData.userData.calUsername = calUsername
          userData.userData.isCalIntegrated = true
          localStorage.setItem("userdata", JSON.stringify(userData));
          setLoading(false)
          navigate("/dashboard");
        } else {
          toast.error("Something went wrong while saving data");
          setLoading(false)
        }
      } else {
        toast.warning("Please fill all details");
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error("Something went wrong while saving data");
    }
  };

  const handleUrlChange = ({ target: { value } }) => {
    setUrl(value);
  };

  function isValidUrl(url) {
    try {
      new URL(url);
      return true;


  } catch (e) {
    console.log("isValidUrl", e)
      return false;
  }
  }

  const handleAddWebhookUrl = async () => {
    const isUrl = isValidUrl(url)
    if(isUrl){
      setLoading(true)
      try {
        const userData = getSavedUserData();
        const finalURL = new URL(url);
        const res = await fetch(`${process.env.REACT_APP_API_URL}/saveWebHookUrl `, {
          method: "POST",
          body: JSON.stringify({
            webHook: finalURL?.origin,
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        });
  
        if (res.status == 200) {
          setLoading(false)
          userData.userData.webHookURL = url
          localStorage.setItem("userdata", JSON.stringify(userData));
          toast.success("WebHook saved successfully");
        }
      } catch (e) {
        setLoading(false)
        toast.error("Something went wrong while saving data");
      }
    }else{
      toast.warning("Please give a valid URL");
    }
    
  }

  const jsonData = {
    action_name: "action name",
    question1: "Answer1",
    question2: "Answer1"
  };
  const jsonString = JSON.stringify(jsonData, null, 2);

  return (
    <div className="agent-background">
      <NavBar selected={"integrations"} />
      <Container fluid className="mt-3">
        <ButtonGroup className="knowledgeBase-tabs">
          <Button
            color="primary"
            outline
            onClick={() => setSelectedTab(1)}
            active={selectedTab === 1}
          >
            Cal Integration
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => setSelectedTab(2)}
            active={selectedTab === 2}
          >
            WebHook
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => setSelectedTab(3)}
            active={selectedTab === 3}
          >
            Host
          </Button>
        </ButtonGroup>
      </Container>

      {selectedTab === 1 && <div className="agent-card">
        <Card>
          <CardBody>
            <Form>
              <FormGroup>
                <Label className="mb-2">Cal.com API Key</Label>
                <Input
                  id="exampleText"
                  name="text"
                  type="text"
                  value={calApiKey}
                  onChange={(e) => handleCalApiKeyChange(e)}
                />
                <FormText>
                  <img src={alertIcon} alt="alert" />
                  You can find it in your Cal.com dashboard security section
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label className="mb-2">Cal.com Event Type ID</Label>
                <Input
                  id="exampleText1"
                  name="text"
                  type="text"
                  value={calEventTypeId}
                  onChange={(e) => handleCalEventTypeChange(e)}
                />
                <FormText>
                  <img src={alertIcon} alt="alert" />
                  You can find it in your Cal.com dashboard event types
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label className="mb-2">Cal.com User Name</Label>
                <Input
                  id="exampleText1"
                  name="text"
                  type="text"
                  value={calUsername}
                  onChange={(e) => handleCalUsernameChange(e)}
                />
                <FormText>
                  <img src={alertIcon} alt="alert" />
                  You can find it in your Cal.com profile section
                </FormText>
              </FormGroup>
            </Form>
            {loading && (
              <div className="common-spinner">
                <Spinner>Loading...</Spinner>{" "}
              </div>
            )}
            <div className="card-bottom-btn">
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </CardBody>
        </Card>
        <FormText>
          <img src={alertIcon} alt="alert" />
          <a href="https://youtu.be/8AvEB6_Ao8o?si=n3Uo18MYta0drzgx" target="_blank">Click here</a> to know more about how to integrate Cal
        </FormText>
      </div>}

      {selectedTab === 2 && <div className="agent-card">
        <Card>
          <CardBody>
          <Form>
            <FormGroup>
              <Label className="mb-2">URL</Label>
              <Input
                id="exampleText"
                name="text"
                type="text"
                value={url}
                onChange={(e) => handleUrlChange(e)}
              />
              <FormText>
                <div className="infowebhook">
                  <spna style={{fontSize: "12px"}}>Add a WebHook URL to receive question and their answer for all the actions created</spna>
                  <div style={{fontSize: "12px", marginTop: "8px"}}>Type: POST</div>
                  <div style={{fontSize: "12px"}}>Response:</div>
                  <pre style={{fontSize: "12px"}}>{jsonString}</pre>
                </div>
              </FormText>
            </FormGroup>
          </Form>
            {loading && (
              <div className="common-spinner">
                <Spinner>Loading...</Spinner>{" "}
              </div>
            )}
            <div className="card-bottom-btn">
              <Button onClick={handleAddWebhookUrl}>Submit</Button>
            </div>
          </CardBody>
        </Card>
      </div>}
      {
        selectedTab === 3 && <AgentDescription />
      }
    </div>
  );
}

export default Integrations;