import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, FormText, Input, Label, Spinner, UncontrolledTooltip } from "reactstrap";
import {
  Modal, ModalHeader, ModalBody, ModalFooter, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Table
} from "reactstrap";
import { Card, CardBody } from "reactstrap";
import emailIcon from "../assets/images/email-icon.png";
import deleteIcon from "../assets/images/delete-icon.png";
import editIcon from "../assets/images/edit-icon.png";
import NavBar from "../components/navbar";
import { getSavedUserData } from "../utils/storage/local";
import { toast } from 'react-toastify';
import { checkIfCalIntegrated } from '../utils/storage/local';
import { StateContext } from "../utils/context/state_context";
import alertIcon from "../assets/images/alert-icon.png"

const questionsNonEditable = ["Booking Date", "Booking Time", "Appointment Id", "Name", "Appointment Id", "Email"]
const categoryNonEditable = ["New Appointment", "Cancel Appointment", "Reschedule Appointment"]

function Questionnaire() {
  const [categoryQuestions, setCategoryQuestions] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [question, setQuestion] = useState("");
  const [modalQuestion, setModalQuestion] = useState([]);
  const [accordianState, setAccordianState] = useState("0");
  const [selectedCategoryAccordian, setSelectedCategoryAccordian] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookAppointment, setBookAppointment] = useState(false)
  const [calIntegrationModal, setCalIntegrationModal] = useState(false)


  const navigate = useNavigate();

  function generateRandomId() {
    let id = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < 4; i++) {
      id += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return id;
  }

  const { actionData, setActionData } = React.useContext(StateContext);

  const getQuestionnaireDetails = async () => {

    try {
      if (actionData.length > 0) {
        setCategoryQuestions(actionData)
        setBookAppointment(true)
      } else {
        setLoading(true)
      }
      const userData = getSavedUserData();
      const res = await fetch(`${process.env.REACT_APP_API_URL}/settings/getAgentDetails`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": userData.tkn
        },
      });
      const data = await res.json();
      if (data?.details?.questionSet) {
        const questions = Object.keys(data?.details?.questionSet)?.map((qstItem) => {
          if (categoryNonEditable.includes(qstItem)) {
            setBookAppointment(true)
          }
          const questionArray = data?.details?.questionSet[qstItem]?.map((arrayItem) => {
            return {
              question: arrayItem,
              status: false
            }
          })
          return {
            name: qstItem,
            question: questionArray,
            uniqueId: generateRandomId()
          }
        })
        console.log("questions===>", questions)
        setActionData(questions)
        setCategoryQuestions(questions)
      }
      setLoading(false)

    } catch (err) {
      console.error(err)
      setLoading(false)
      toast.error("Something went wrong while fetching data");
    }
  }

  useEffect(() => {
    getQuestionnaireDetails()
  }, [])

  const resetState = () => {
    setCategoryName("")
    setModalQuestion([])
    setQuestion("")
    setSelectedCategoryAccordian("")
  }

  const handleAccordian = (id) => {
    if (accordianState === id) {
      setAccordianState();
    } else {
      setAccordianState(id);
    }
  };
  const handleAddCategory = () => {
    setCategoryModal(!categoryModal);
    resetState()
  };

  const handleCalIntegrationModal = () => {
    setCalIntegrationModal(!calIntegrationModal);
  };

  const saveCategory = () => {
    if (categoryName && modalQuestion?.length > 0 && selectedCategoryAccordian === "") {
      setCategoryQuestions((prev) => {
        return [...prev, { name: categoryName, question: modalQuestion, uniqueId: generateRandomId() }];
      });
      setCategoryModal(!categoryModal);
      setCategoryName("");
      setModalQuestion([])
    } else if (categoryName && modalQuestion?.length > 0 && selectedCategoryAccordian !== "") {
      const categoryCopy = categoryQuestions?.slice()
      const indexToEdit = categoryCopy.findIndex((catItem) => catItem?.uniqueId === selectedCategoryAccordian)
      categoryCopy[indexToEdit] = { name: categoryName, question: modalQuestion }
      setCategoryQuestions(categoryCopy)
      setCategoryModal(!categoryModal);
      setCategoryName("");
      setModalQuestion([])
      setSelectedCategoryAccordian("")
    } else {
      toast.warning("Please fill all details");
    }
  };

  const addQuestionToArray = () => {
    console.log("modalQuestion", modalQuestion)
    questionsNonEditable?.forEach((item) => {
      if(item.toLowerCase() === question?.question.trim().toLowerCase()){
        toast.warning("Already added");
        return
      }
    })
    if (question) {
      const trimQuestion = {
        question: question?.question.trim(),
        status: question?.status
      }
      setModalQuestion((prev) => [...prev, trimQuestion]);
      setQuestion({});
    }
  };

  const handleCheckboxChange = (e) => {
    let modalQuestionCopy = modalQuestion.slice()
    const indexToedit = modalQuestionCopy.findIndex(qus => qus?.question === e.target.id);
    modalQuestionCopy[indexToedit].status = e.target.checked
    setModalQuestion(modalQuestionCopy);
  }

  const removeQuestion = (question) => {
    let modalQuestionCopy = modalQuestion.slice()
    modalQuestionCopy = modalQuestionCopy.filter(str => str !== question);
    setModalQuestion(modalQuestionCopy);
  }

  const removeMultipleQuestion = () => {
    let modalQuestionCopy = modalQuestion.slice()
    const filteredQuestionCopy = modalQuestionCopy.filter(str => str?.status === false);
    setModalQuestion(filteredQuestionCopy);
  }

  const handleCategoryDelete = (categoryName) => {
    let categoryCopy = categoryQuestions?.slice()
    categoryCopy = categoryCopy.filter(str => str.name !== categoryName);
    setCategoryQuestions(categoryCopy);
  }

  const saveAllCategoryQuestion = async () => {
    setLoading(true)
    const object = {}
    categoryQuestions?.forEach((dataItem) => {
      const questionArray = dataItem?.question?.map((qst) => {
        return qst?.question
      })

      object[dataItem.name] = questionArray
    })

    const userData = getSavedUserData();
    const res = await fetch(`${process.env.REACT_APP_API_URL}/settings/saveQuestionnaire`, {
      method: "POST",
      body: JSON.stringify({
        questionSet: object,
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": userData.tkn
      },
    });
    if (res.status === 200) {
      userData.userData.questionSet = object
      localStorage.setItem("userdata", JSON.stringify(userData));
      setLoading(false)
      navigate("/dashboard");
    } else {
      setLoading(false)
      navigate("/actions");
      toast.error("Something went wrong while saving data");
    }
  }

  const handleCategoryEdit = (categoryToEdit, index) => {
    console.log("handleCategoryEdit", categoryToEdit?.uniqueId)
    setSelectedCategoryAccordian(categoryToEdit?.uniqueId);
    setCategoryName(categoryToEdit?.name);
    setModalQuestion(categoryToEdit?.question)
    setCategoryModal(!categoryModal);
  }

  const handleAddBookAppointmentCategory = async () => {
    if (checkIfCalIntegrated()) {
      const dataToAdd = [
        {
          name: "New Appointment",
          question: [
            {
              question: "Name",
              status: false
            },
            {
              question: "Email",
              status: false
            },
            {
              question: "Booking Date",
              status: false
            },
            {
              question: "Booking Time",
              status: false
            },
          ],
          uniqueId: generateRandomId()
        },
        {
          name: "Cancel Appointment",
          question: [
            {
              question: "Appointment Id",
              status: false
            }
          ],
          uniqueId: generateRandomId()
        },
        {
          name: "Reschedule Appointment",
          question: [
            {
              question: "Appointment Id",
              status: false
            },
            {
              question: "Booking Date",
              status: false
            },
            {
              question: "Booking Time",
              status: false
            },
          ],
          uniqueId: generateRandomId()
        },
      ]
      setCategoryQuestions((prev) => [...prev, ...dataToAdd])
      setBookAppointment(true)
    } else {
      setCalIntegrationModal(true)
    }
  }

  const handleRemoveBookAppointmentCategory = () => {
    setBookAppointment(false)
    const copyCategoryQuestions = categoryQuestions?.slice()
    const filteredQuestions = copyCategoryQuestions?.filter((qusItem) => !categoryNonEditable.includes(qusItem?.name))
    setCategoryQuestions(filteredQuestions)
  }

  const handleIntegrateCal = () => {
    navigate("/settings")
  }

  const renderNocategoryCard = () => {
    return (
      <div className="action-card">
        {/* {loading && <Spinner style={{zIndex:100, marginBottom: "20px"}} color="light">Loading...</Spinner>} */}
        <Card>
          <CardBody>
            <img src={emailIcon} alt="email-icon" />
            <Button size="small" onClick={handleAddCategory}>+</Button>
            <h4>Actions</h4>
            <p>
              If you have actions related to Book Appointment then click button
              below and you are half way done
            </p>
            <p>
              To add customized actions click add button above
            </p>
            {/* <p>If you have action related to Book Appointment </p> */}
            <Button onClick={handleAddBookAppointmentCategory}>Book Appointment Actions</Button>

          </CardBody>
        </Card>
      </div>
    );
  };

  const renderCategoryQuestions = () => {
    return categoryQuestions?.filter((qusItem) => categoryNonEditable.includes(qusItem?.name)).map((categoryItem, index) => {
      console.log("renderCategoryQuestions", categoryItem)
      const quesArray = categoryQuestions?.filter((qusItem) => categoryNonEditable.includes(qusItem?.name))[index]?.question;
      return (
        <div key={`book-${index}`} className="accordion-data">
          <AccordionItem>
            <AccordionHeader targetId={`book-${index}`}>
              {categoryItem.name}
            </AccordionHeader>
            <AccordionBody accordionId={`book-${index}`}>
              <Table className="book-appointment-table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Type</th>
                  </tr>

                </thead>
                <tbody>
                  {quesArray.length > 0 &&
                    quesArray.map((quesItem, index) => {
                      return (
                        <tr>
                          <td>{quesItem.question}</td>
                          <td>{!questionsNonEditable.includes(quesItem?.question) ? "Custom" : "Mandatory"}</td>
                        </tr>
                      );
                      // <div key={index} className="mb-3">{quesItem.question}</div>;
                    })}
                </tbody>
              </Table>
              {/* <Button onClick={() => handleAddQuestion(categoryItem)}>
              Add Question
            </Button> */}
            </AccordionBody>
          </AccordionItem>
          <div className="accordian-icons">
            <img
              src={editIcon}
              alt="edit-icon"
              onClick={() => handleCategoryEdit(categoryItem, index)}
            />
            {!categoryNonEditable.includes(categoryItem?.name) && (
              <img
                src={deleteIcon}
                alt="delete-icon"
                onClick={() => handleCategoryDelete(categoryItem.name)}
              />
            )}
          </div>
        </div>
      );
    });
  };

  const renderOtherCategoryQuestions = () => {
    return categoryQuestions?.filter((qusItem) => !categoryNonEditable.includes(qusItem?.name)).map((categoryItem, index) => {
      const quesArray = categoryQuestions?.filter((qusItem) => !categoryNonEditable.includes(qusItem?.name))[index]?.question;
      console.log("renderOtherCategoryQuestions", categoryItem)
      return (
        <div key={`other-${index}`} className="accordion-data">
          <AccordionItem>
            <AccordionHeader targetId={`other-${index}`}>
              {categoryItem.name}
            </AccordionHeader>
            <AccordionBody accordionId={`other-${index}`}>
              {/* {quesArray.length > 0 &&
                quesArray.map((quesItem, index) => {
                  return <div key={index} className="mb-3">{quesItem.question}</div>;
                })} */}
              <Table className="book-appointment-table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {quesArray.length > 0 &&
                    quesArray.map((quesItem, index) => {
                      return (
                        <tr>
                          <td>{quesItem.question}</td>
                          <td>{!questionsNonEditable.includes(quesItem?.question) ? "Custom" : "Mandatory"}</td>
                        </tr>
                      );
                      // <div key={index} className="mb-3">{quesItem.question}</div>;
                    })}
                </tbody>
              </Table>
              {/* <Button onClick={() => handleAddQuestion(categoryItem)}>
              Add Question
            </Button> */}
            </AccordionBody>
          </AccordionItem>
          <div className="accordian-icons">
            <img src={editIcon} alt="edit-icon" onClick={() => handleCategoryEdit(categoryItem, index)} />
            {!categoryNonEditable.includes(categoryItem?.name) && <img src={deleteIcon} alt="delete-icon" onClick={() => handleCategoryDelete(categoryItem.name)} />}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="agent-background">
      <NavBar selected={"actions"} />
      {loading && (
        <div className="question-page-loader-container">
          <Spinner style={{ zIndex: 100, marginBottom: "20px" }} color="dark">
            Loading...
          </Spinner>
        </div>
      )}

      <div className="d-flex align-items-center flex-column h-100 p-4">
        <div className="email-categories-accordion">
          <div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <h4 className="accordian-heading">Book Appointment Actions</h4>
              {!bookAppointment ? (
                <Button onClick={handleAddBookAppointmentCategory} outline>
                  Add
                </Button>
              ) : (
                <div
                  className="delete-icon px-2"
                  onClick={handleRemoveBookAppointmentCategory}
                >
                  <img
                    src={deleteIcon}
                    alt="delete-icon"
                    className="delete-category-icon"
                  />
                </div>
              )}
            </div>
            <p className="alert-message">
              From booking to rescheduling or cancelling, manage your
              appointments with ease.
            </p>
          </div>
          <Accordion open={accordianState} toggle={handleAccordian}>
            {" "}
            {renderCategoryQuestions()}
          </Accordion>
          <hr />
          <div>
            <div className="d-flex align-items-center justify-content-between mt-1 mb-1">
              <h4 className="accordian-heading">Other Actions</h4>
              <div>
                <Button onClick={handleAddCategory} outline>
                  Add
                </Button>
              </div>

            </div>
          </div>
          <p className="alert-message">
            Customize your own category, letting AI seamlessly manage the emails
            for you.
          </p>
          <Accordion open={accordianState} toggle={handleAccordian}>
            {" "}
            {renderOtherCategoryQuestions()}
          </Accordion>
        </div>

        {/* {loading && <Spinner color="primary">Loading...</Spinner>} */}
        <div className="accordion-start-btn">
          <Button outline onClick={saveAllCategoryQuestion}>
            Save
          </Button>
        </div>
      </div>

      <Modal
        centered
        className="email-category-modal"
        isOpen={categoryModal}
        toggle={handleAddCategory}
      >
        <ModalHeader toggle={handleAddCategory}>
          {selectedCategoryAccordian === "" ? "Add Action" : "Edit Action"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-3">
              <Label className="mb-2">Action Name</Label>
              <Input
                type="text"
                value={categoryName}
                onChange={(event) => {
                  setCategoryName(event.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="mb-2">Question</Label>
              <div className="d-flex align-items-center ">
                <Input
                  type="text"
                  value={question?.question || ""}
                  onChange={(event) => {
                    // handleAddQuestion(event.target.value);
                    setQuestion({
                      question: event.target.value,
                      status: false,
                    });
                    // setQuestion((prev)=> [...prev, event.target.value]);
                  }}
                />
                <Button
                  className="add-btn"
                  outline
                  onClick={addQuestionToArray}
                >
                  Add
                </Button>
              </div>
            </FormGroup>
            {/* <div className="remove-items-container p-0 h-auto">
              <div className="remove-items">
                <div className="d-flex align-items-center">
                  <Input
                    type="checkbox"
                    id={"Book Appointment"}
                    onChange={handleAppointmentCheckboxChange}
                    checked={bookAppointment}
                    className="mt-0"
                  />{" "}
                  <Label check className="mt-0 mb-1">Book Appointment</Label>
                </div>
              </div>
            </div> */}
            <div className="selected-items-container">
              <div className="items-header">
                <h5>Questions</h5>
                {/* <div onClick={removeMultipleQuestion}>
                  <img src={deleteIcon} alt="delete-icon" />
                </div> */}
              </div>
              <FormGroup check>
                <div className="remove-items-container">
                  {modalQuestion?.map((quesItem, index) => {
                    return (
                      <div key={index} className="remove-items">
                        <div className="d-flex align-items-center">
                          <Input
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            id={quesItem?.question}
                            name={quesItem?.question}
                            checked={quesItem?.status}
                          />{" "}
                          <Label check>{quesItem?.question}</Label>
                        </div>
                        {!questionsNonEditable.includes(quesItem?.question) && (
                          <Button
                            className="remove-btn"
                            onClick={() => removeQuestion(quesItem)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </FormGroup>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={handleAddCategory}>
            Cancel
          </Button>
          <Button color="primary" onClick={saveCategory}>
            Save
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        className="email-category-modal integrate-modal"
        isOpen={calIntegrationModal}
        toggle={handleCalIntegrationModal}
      >
        {/* <ModalHeader toggle={handleCalIntegrationModal}>Please</ModalHeader> */}
        <ModalBody>
          <h4 className="accordian-heading">Please integrate Cal </h4>
          <p>
            Integrating Cal ensures seamless scheduling by syncing real-time
            updates, preventing overbooking, and centralizing all your
            appointments for easy access.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={handleCalIntegrationModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleIntegrateCal}>
            Integrate Cal
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Questionnaire;
