import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getSavedUserData } from "../../utils/storage/local";

const Payment = (props) => {
  const {
    paymentModal,
    setIsPaymentModalOpen,
    amount,
    setAmount,
    title,
    inputLabel="dfjsd",
    selectedPlan,
    addInProgressTransaction
  } = props;
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [invoiceUs, setInvoiceUs] = useState(true);
  const [loading, setLoading] = useState(false) 

  const getStripePromise = async () => {
    setLoading(true)
   try{
     const userData = getSavedUserData();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/stripeConfig`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        }
      );
      const data = await res.json();
      
      const publishableKey = await loadStripe(
        data?.publishableKey
      );
  
      setStripePromise(publishableKey);

   } catch(e){
    console.log(e)
   }
  };

  const getClientSecret = async () => {
    try{
      const userData = getSavedUserData();
      const body = {
        currency: "USD",
        amount:  amount * 100,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/paymentIntent`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        }
      );
      const clientSecretPromise = await res.json();
      console.log("clientSecretPromise", clientSecretPromise)
      const clientSecretKey = clientSecretPromise?.clientSecret;
      if (clientSecretPromise && clientSecretKey) {
        setIsCheckoutModalOpen(true);
      }
      setClientSecret(clientSecretKey);
      setLoading(false)
    }catch(e){
      console.log(e)
    }
    
  };

  useEffect(() => {
    getStripePromise();
    getClientSecret();
  }, []);
  

  const onCancel = () => {
        setIsPaymentModalOpen(false);
        // setAmount(0);
      }
console.log("clientSecret",  stripePromise, clientSecret)
  return (

    (clientSecret && stripePromise ) && <Modal
      centered
      className="email-category-modal integrate-modal"
      isOpen={paymentModal}
      toggle={onCancel}
    >
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <div>
          {(clientSecret && stripePromise ) &&  (
            <Elements options={{ clientSecret }} stripe={stripePromise}>
              <CheckoutForm
                title={title}
                isCheckoutModalOpen={isCheckoutModalOpen}
                setIsCheckoutModalOpen={setIsCheckoutModalOpen}
                inputLabel={inputLabel}
                inputAmount={amount}
                setAmount={setAmount}
                invoiceUs={invoiceUs}
                setIsPaymentModalOpen={setIsPaymentModalOpen}
                clientSecret={clientSecret}
                stripePromise={stripePromise}
                selectedPlan={selectedPlan}
                addInProgressTransaction={addInProgressTransaction}
              />
            </Elements>
          )}
        </div>
      </ModalBody>
     </Modal>
  );
};

export default Payment;
