import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Spinner,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import alertIcon from "../assets/images/alert-icon.png";
import emailIcon from "../assets/images/email-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/navbar";
import { getSavedUserData } from "../utils/storage/local";
import { toast } from "react-toastify";
import Payment from "../components/payments.js/index";
import emailCards from "../assets/images/email-card.png";
import redCross from "../assets/images/green-tick.svg";
import greenTick from "../assets/images/red-cross.svg";
import { StateContext } from "../utils/context/state_context";

function Subscription() {
  const navigate = useNavigate();

  const [calApiKey, setCalApiKey] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [amount, setAmount] = useState(100);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const userData = getSavedUserData();
  const [loading, setLoading] = useState(false);
  const [subscribedPlan, setSubscribedPlan] = useState({});
  const [isMonthlyPlanType, setIsMonthlyPlanType] = useState(false);
  const { subscriptionData, setSubscriptionData } =
    React.useContext(StateContext);

  const openPaymentModal = async (item) => {
    // await addInProgressTransaction(item)
    setSelectedPlan(item);
    setPaymentModal(true);
  };

  useEffect(() => {
    getSubscriptionPlans();
  }, []);
  const subscribe = async (plan) => {
    try {
      const body = {
        priceId: plan?.priceId,
        planName: plan?.name,
        planId: plan?._id
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/createSubscriptionSession`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn
          }
        }
      );
      const data = await res.json();
      // window.location.assign(res.data);
      console.log("data--->", data);
      window.location.href = data.redirectURL;
    } catch (err) {
      toast.error("Something went wrong while fetching data");
    }
  };
  const getSubscriptionPlans = async () => {
    setLoading(true)
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/subscriptionPlan/get`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn
          }
        }
      );
      const data = await res.json();
      console.log("data--->", data);
      setSubscriptionPlans(data?.plans);
      setSubscribedPlan(data?.userSubscription);
      setLoading(false)
      console.log("plans", data?.plans);
    } catch (err) {
      setLoading(false)
      toast.error("Something went wrong while fetching data");
    }
  };

  const addInProgressTransaction = async () => {
    try {
      setLoading(true);
      const body = {
        status: "inProgress",
        amount: selectedPlan?.amount * 100,
        planId: selectedPlan?._id
      };
      const transaction = await fetch(
        `${process.env.REACT_APP_API_URL}/saveTransactionRecord`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn
          }
        }
      );
      const data = await transaction.json();
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
    }
  };

  const handleFreeTrialPeriod = async (plan) => {
    try {
      setLoading(true);
      const body = {
        status: "complete",
        amount: plan?.amount * 100,
        planId: plan?._id,
        freeTrial: true
      };
      const transaction = await fetch(
        `${process.env.REACT_APP_API_URL}/saveTransactionRecord`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn
          }
        }
      );
      const data = await transaction.json();
      userData.userData.isFreeTrialTaken = true;
      console.log("localStorage", userData, data);
      localStorage.setItem("userdata", JSON.stringify(userData));
      setLoading(false);
      toast.success("Congratulations! your free trial period is activated");
      navigate("/dashboard");
      return data;
    } catch (e) {
      setLoading(false);
    }
  };
  console.log("subscriptionData", subscriptionData);
  const renderSubscriptionCards = () => {
    const filteredPlan = subscriptionPlans?.filter((item) => {
      if (isMonthlyPlanType) {
        return item?.planType === "month";
      } else {
        return item?.planType === "year";
      }
    });
    return filteredPlan?.map((item) => {
      return (
        <Col xl="3" lg="4" md="6">
          <div className="plan-cards ">
            <h3>{item?.name}</h3>
            {subscribedPlan?.planId === item?._id &&
              subscribedPlan?.freeTrial &&
              !userData?.userData?.isSubscriptionEnded && (
                <div className="free-trail">Free trial activated</div>
              )}
            {!userData?.userData?.isFreeTrialTaken && (
              <Button
                className="free-trail-btn"
                onClick={() => handleFreeTrialPeriod(item)}
              >
                Try 14 days Free trial
              </Button>
            )}
            {/* <div className="plan-list"> */}
            {/* <p>
                <i class="fa-solid fa-check"></i> {item?.email_credits} email
                credits
              </p> */}
            {/* <p>
                <i class="fa-solid fa-check"></i>For{" "}
                {item?.duration + " " + item?.duration_type}
              </p> */}
            {/* </div> */}
            <img className="email-cards" src={emailCards} alt="emailCards" />
            <div className="email-credits">
              <span>
                <img src={greenTick} alt="greenTick" />
                {item?.email_credits}
              </span>{" "}
              email credits
            </div>
            {item?.name === "Supreme" ? (
              <div className="email-credits">
                <span>
                  <img src={greenTick} alt="greenTick" />
                  Credits carry forward
                </span>
              </div>
            ) : (
              <div className="email-credits">
                <span>
                  <img src={redCross} alt="redCross" />
                  Credits carry forward
                </span>
              </div>
            )}
            <div className="plan-button">
              <h6 className="plan-price">
                <span>
                  ${item?.amount}
                  {"/"}
                  {item?.planType}
                </span>
              </h6>
              <Button
                className="purple-solid-btn"
                onClick={() => subscribe(item)}
                disabled={item?._id == subscribedPlan?.planId &&
                  !userData?.userData?.isSubscriptionEnded ? true : false}
              >
                {item?._id == subscribedPlan?.planId &&
                  !userData?.userData?.isSubscriptionEnded
                  ? "Active"
                  : "Buy now"}
              </Button>
              {/* <h6 className="valid-till">Valid till 24/02/2024</h6> */}
            </div>
          </div>
        </Col>
      );
    });
  };

  return (
    <div className="agent-background">
      <NavBar selected={"integrations"} />
      {loading && (
        <div className="loader-container common-spinner">
          {" "}
          <Spinner
            style={{ position: "relative", zIndex: 100, color: "#4f43be" }}
          >
            Loading...
          </Spinner>{" "}
        </div>
      )}
      <Container>
        <div className="plan-for-business">
          <h3 className="text-center plan-title">
            The Right Plan for Your Business
          </h3>
          <p className="text-center">
            We have several powerful plans to showcase your business and get
            discovered
            <br />
            as a creative entrepreneurs. Everything you need.
          </p>
          <div className="text-center">
            You have <strong>{subscriptionData?.emailCredits}</strong> email credits and{" "}
            <strong> {subscriptionData?.subscriptionDaysLeft}</strong> days left before your
            subscription is due.
          </div>
          <p className="text-center mb-2">
            If you want to manage your subscription{" "}
            <a
              target="_blank"
              href="https://billing.stripe.com/p/login/28o9DfaZC0Ky3YI8ww"
            >
              click here
            </a>
          </p>
          {/* <FormGroup switch>
            <Input
              type="switch"
              checked={isMonthlyPlanType}
              onClick={() => {
                setIsMonthlyPlanType(!isMonthlyPlanType);
              }}
            />
            <Label check>Switch to monthly</Label>

          </FormGroup> */}
          <div className="monthly-tabs">
            <Nav className="month-tabs" tabs>
              <NavItem>
                <NavLink
                  // className="active"
                  className={isMonthlyPlanType === true ? "active" : ""}
                  onClick={() => setIsMonthlyPlanType(true)}
                >
                  Monthly
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={isMonthlyPlanType === false ? "active" : ""}
                  onClick={() => setIsMonthlyPlanType(false)}
                >
                  Yearly
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <Row className="justify-content-center">
            {subscriptionPlans && renderSubscriptionCards()}
          </Row>
        </div>
        {paymentModal && (
          <Payment
            paymentModal
            setIsPaymentModalOpen={setPaymentModal}
            amount={selectedPlan?.amount || 0}
            setAmount={setAmount}
            selectedPlan={selectedPlan}
            addInProgressTransaction={addInProgressTransaction}
          ></Payment>
        )}
      </Container>
    </div>
  );
}

export default Subscription;
