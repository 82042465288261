import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { getSavedUserData } from "../utils/storage/local";
import { useSearchParams } from "react-router-dom";
import { Spinner } from 'reactstrap';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const userData = getSavedUserData();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true)
  const handleReturnHome = () => {
    navigate('/dashboard');
  };

  const createCustomer = async (name, email) => {
    const body = {
      name,
      email
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/createSubscriptionUser`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "Authorization": userData.tkn
        },
      }
    );
    console.log("response", response)
  }

  const getSession = async (sessionId, planName, planId) => {
    try {

      const body = {
        sessionId
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getSessionWithId`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        }
      );

      const sessionData = await response.json();
      console.log("sessionData", sessionData);
      await createCustomer(sessionData?.session?.customer_details?.name, sessionData?.session?.customer_details?.email)

      const transactionBody = {
        status: sessionData?.session?.status,
        transactionId: sessionData?.session?.id,
        amount: sessionData?.session?.amount_total,
        planId,
        planName,
        subscriptionId: sessionData?.session?.subscription
      };
      await fetch(
        `${process.env.REACT_APP_API_URL}/saveTransactionRecord`,
        {
          method: "POST",
          body: JSON.stringify(transactionBody),
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        }
      );
      userData.userData.isSubscriptionEnded = false
      console.log("localStorage", userData)
      localStorage.setItem("userdata", JSON.stringify(userData));
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }

  };


  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    const planName = searchParams.get("plan_name");
    const planId = searchParams.get("plan_id");
    getSession(sessionId, planName, planId)
  }, [])

  return (
    <div style={styles.container}>
      {loading && <div className='loader-container common-spinner'> <Spinner style={{ position: "relative", zIndex: 100, color: "#4f43be" }} >Loading...</Spinner>     </div>}

      <h2 style={styles.successText}>
        Payment Successful!
      </h2>
      <p style={styles.infoText}>
        Congratulations! Your payment was successful. Thank you for your purchase.
      </p>
      <button style={styles.button} onClick={handleReturnHome}>Return to Home</button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh'
  },
  successText: {
    color: 'green',
    fontSize: 50,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  infoText: {
    textAlign: 'center',
    marginVertical: 20,
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#298000',
    color: 'white',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
  },
};

export default PaymentSuccess;

