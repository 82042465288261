import React, { useState, useEffect } from "react";
import userIcon from "../assets/images/user-icon.png";
import logo from "../assets/images/logo.png";
import {
  Collapse,
  Navbar,
  NavItem,
  NavbarToggler,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  NavbarBrand,
  CloseButton
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getSavedUserData } from "../utils/storage/local";
import { toast } from "react-toastify";
import SubscriptionIcon from "../assets/images/subscribtion.svg";
import { StateContext } from "../utils/context/state_context";

const NAVBAR_ITEMS = [
  {
    name: "Dashboard",
    path: "/dashboard",
    key: "dashboard"
  },
  // {
  //   name: "Host",
  //   path: "/agent-description",
  //   key: "agentDescription"
  // },
  {
    name: "Actions",
    path: "/actions",
    key: "actions"
  },
  {
    name: "Labels",
    path: "/labels",
    key: "labels"
  },
  // {
  //   name: "Integrations",
  //   path: "/integrations",
  //   key: "integrations"
  // },
  {
    name: "Bookings",
    path: "/bookings",
    key: "bookings"
  },
  {
    name: "Chat",
    path: "/chat",
    key: "chat"
  }
];

function NavBar(selected) {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubscribeBanner, setshowSubscribeBanner] = useState(false)
  const [subscriptionDaysLeft, setSubscriptionDaysLeft] = useState(0)
  const [showDaysLeft, setShowDaysLeft] = useState(false)

  const { subscriptionData, setSubscriptionData } =
    React.useContext(StateContext);

  const userInfo = getSavedUserData();
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  const userData = getSavedUserData();

  const checkVerification = async () => {
    try {
      const userData = getSavedUserData();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/checkSubscription`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.tkn
          }
        }
      );
      if (res.status === 401) {
        handleLogout()
        return;
      }
      if (res) {
        const data = await res.json();
        console.log("subscription", data)
        userData.userData.isSubscriptionEnded = data?.subscriptionData?.isSubscriptionEnded
        setshowSubscribeBanner(data?.subscriptionData?.isSubscriptionEnded)
        setSubscriptionDaysLeft(data?.subscriptionData?.subscriptionDaysLeft)
        setShowDaysLeft(data?.subscriptionData?.isFreeTrial)
        setSubscriptionData({
          isSubscriptionEnded: data?.subscriptionData?.isSubscriptionEnded,
          subscriptionDaysLeft: data?.subscriptionData?.subscriptionDaysLeft,
          emailCredits: data?.subscriptionData?.emailCredits
        })
        console.log("localStorage", userData, data)
        localStorage.setItem("userdata", JSON.stringify(userData));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    checkVerification();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userdata");
    navigate("/");
  };
  const handleSettingsClick = () => {
    navigate("/settings");
  };
  const handleSubscriptionClick = () => {
    navigate("/subscription");
  };
  const handleLabelsClick = () => {
    navigate("/labels");
  };
  const handSupportClick = () => {
    navigate("/support");
  };
  const handleKnowledgeBaseClick = () => {
    navigate("/knowledge-base");
  };
  const handleBrandNameClick = () => {
    navigate("/dashboard");
  };
  const handleNavbarItemClick = (link) => {
    const data = getSavedUserData();
    console.log("link", data, link);
    // if (link === "/agent-description") {
    //   navigate(link);
    // } 
    if (link === "/actions") {
      if (data?.userData?.agentDescription) {
        navigate(link);
      } else {
        toast.warning("Please fill Agent description first");
      }
    } else if (link === "/dashboard") {
      if (data?.userData?.agentDescription && data?.userData?.questionSet) {
        navigate(link);
      } else {
        toast.warning("Please complete previous step first");
      }
    } else if (link === "/settings") {
      navigate(link);
    } else if (link === "/bookings") {
      navigate(link);
    } else if (link === "/labels") {
      navigate(link);
    }else if (link === "/chat") {
      navigate(link);
    }
  };
  const gotoSubscriptionPage = () => {
    navigate("/subscription");
  };

  return (
    <div className="top-header">
      <Navbar expand="lg">
        <NavbarBrand href="" onClick={handleBrandNameClick}>
          <img src={logo} alt="logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <CloseButton variant="white" className="close-nav" onClick={toggle} />
          <div className="top-nav-link">
            {NAVBAR_ITEMS.map((navItem) => {
              return (
                <NavItem
                  active={selected?.selected === navItem?.key ? true : false}
                >
                  <NavLink onClick={() => handleNavbarItemClick(navItem?.path)}>
                    {navItem?.name}
                  </NavLink>
                </NavItem>
              );
            })}
          </div>
          {showDaysLeft && <div className="subscription-days-left">Free trial ends in {subscriptionDaysLeft <= 0 ? 0 : subscriptionDaysLeft} days</div>}
          <div className="user-dropdown">
            <UncontrolledDropdown direction="down">
              <DropdownToggle caret color="primary">
                {userInfo?.userData?.name}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <div onClick={handleKnowledgeBaseClick}>Knowledge Base</div>
                </DropdownItem>
                <DropdownItem header>
                  <div onClick={handleSettingsClick}>Settings</div>
                </DropdownItem>
                <DropdownItem header>
                  <div onClick={handleSubscriptionClick}>Subscription</div>
                </DropdownItem>
                {/* <DropdownItem header>
                  <div onClick={handleLabelsClick}>Labels</div>
                </DropdownItem> */}
                <DropdownItem header>
                  <div onClick={handSupportClick}>Support</div>
                </DropdownItem>
                <DropdownItem header>
                  <div onClick={handleLogout}>Logout</div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Collapse>
      </Navbar>
      {showSubscribeBanner && (
        <div className="subscribtion-bar" onClick={gotoSubscriptionPage}>
          Oops! It seems like your subscription has expired and new email are
          not getting addresed, please renew your subscription to continue{" "}
          <span className="subscribe-txt">
            Subscribe <img src={SubscriptionIcon} alt="SubscriptionIcon" />
          </span>
        </div>
      )}
    </div>
  );
}

export default NavBar;
