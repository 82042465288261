import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  Label,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

const AddEditLabelModal = (props) => {
  const {
    isOpen,
    handleAddCategory,
    selectedCategoryAccordian,
    selectEditData,
    url,
    SetShowloader,
    handlegetList,
    userData,
  } = props;
  console.log("selectEditDataselectEditData", selectEditData);

  const [input, setInput] = useState({
    labelName: "",
    description: "",
  });

  useEffect(() => {
    if (selectEditData) {
      setInput({
        labelName: selectEditData?.label,
        description: selectEditData?.labelDescription,
      });
    } else {
      setInput({
        labelName: "",
        description: "",
      });
    }
  }, [selectEditData]);

  const handleSaveClick = async () => {
    let apiEndPoint = "";
    let payload = {};

    if (selectEditData) {
      apiEndPoint = `${url}/updateLabel`;
      payload = {
        labelId: selectEditData?.labelId,
        name: input?.labelName,
        description: input?.description,
      };
    } else {
      apiEndPoint = `${url}/createLabel`;
      payload = {
        name: input?.labelName,
        description: input?.description,
      };
    }
    console.log("endpoint payload", apiEndPoint, payload);
    SetShowloader(true);
    try {
      const res = await fetch(apiEndPoint, {
        method: "POST",
        headers: {
          Authorization: userData.tkn,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const response = await res.json();
      if (response?.success) {
        SetShowloader(false);
        toast.success("Created Successfully");
        handlegetList();
        setInput({
          labelName: "",
          description: "",
        });
      }
    } catch (error) {
      console.error("error", error);
      SetShowloader(false);
    }
  };

  console.log("inpotttt", input);
  //   const [labelName, setLabelName] = useState("");
  //   const [description, setDescription] = useState("");

  const onChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setInput((prevState) => ({ ...prevState, [name]: value }));
  };

  const onCancelClick = () => {
    setInput({
      labelName: "",
      description: "",
    });
    handleAddCategory();
  };

  return (
    <>
      <Modal
        centered
        className="email-category-modal"
        isOpen={isOpen}
        // toggle={handlegetList}
      >
        <ModalHeader toggle={() => onCancelClick()}>
          {selectEditData ? "Edit" : "Create"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-3">
              <Label className="mb-2">Name</Label>
              <Input
                type="text"
                name="labelName"
                value={input?.labelName}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mb-2">Description</Label>
              <Input
                type="textarea"
                name="description"
                rows="4"
                className="resize-none"
                value={input?.description}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={() => onCancelClick()}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleSaveClick()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddEditLabelModal;
