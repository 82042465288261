import React from "react";
import { useEffect, useState } from "react";
import { Form, Row, FormGroup, Label, Container, Input, Button, FormText, Spinner, ModalBody, ModalFooter, Modal, ModalHeader, Col, ButtonGroup, Card, CardBody, Pagination, PaginationLink, PaginationItem } from 'reactstrap';
import alertIcon from "../assets/images/alert-icon.png"
import emailIcon from "../assets/images/email-icon.png"
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from '../components/navbar'
import { getSavedUserData } from "../utils/storage/local";
import { toast } from 'react-toastify';
import { StateContext } from "../utils/context/state_context";



function AgentDescription() {
  const navigate = useNavigate();

  const [addKnowledgeBaseModal, setAddKnowledgeBaseModal] = useState(false);
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setanswere] = useState('');
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [modalLoader, setModalLoader] = useState(false)
  const [editMode, setEditMode] = useState('')
  const [selectedTab, setSelectedTab] = useState(1);
  const [url, setUrl] = useState("");
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [deleteQuestion, setDeleteQuestion] = useState("")


  const [errorMessage, setErrorMessage] = useState('');

  const { agentData, setAgentData } = React.useContext(StateContext);
  const getQuestionDetails = async () => {
    // if (agentData?.name) {
    //   setAgentDescription(agentData?.agentDescription)
    //   setAgentName(agentData?.name)
    // } else {
    //   setLoading(true)
    // }
    setLoading(true)
    try {
      const userData = getSavedUserData();
      const res = await fetch(`${process.env.REACT_APP_API_URL}/knowledgeBase/get?page=${page}&limit=9`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": userData.tkn
        },
      });
      const data = await res.json();
      setKnowledgeData(data?.data)
      setTotalPages(data?.pagination?.totalPages)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
      toast.error("Something went wrong while fetching data");
    }
  }

  useEffect(() => {
    getQuestionDetails()
  }, [page])

  const handleAnswereChange = (event) => {
    const { value } = event.target;
    if (value.length <= 2000) {
      setanswere(value);
      setErrorMessage("");
    } else {
      setErrorMessage("Description cannot exceed 500 characters");
    }
  };

  const handleQuestionChange = (event) => {
    const { value } = event.target;
    if (value.length <= 600) {
      setQuestion(value);
      setErrorMessage("");
    } else {
      setErrorMessage("Description cannot exceed 500 characters");
    }
  };

  const handleAddToKBCard = async () => {
    setEditMode("")
    setAddKnowledgeBaseModal(true)
  };

  const handleAddKBModal = () => {
    setAddKnowledgeBaseModal(!addKnowledgeBaseModal);
    setanswere("");
    setQuestion("");
  };

  const handleDeleteConfirmationModal = () => {
    setDeleteConfirmationModal(!deleteConfirmationModal);
    setDeleteQuestion("")
  };

  const handleCancelKBcard = async () => {
    setAddKnowledgeBaseModal(false)
  };

  const handleCreateKB = async () => {
    setModalLoader(true)
    try {
      if (question !== "" || answer !== "") {
        const userData = getSavedUserData();
        const res = await fetch(`${process.env.REACT_APP_API_URL}/knowledgeBase/add`, {
          method: "POST",
          body: JSON.stringify({
            question: question,
            answer: answer
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": userData.tkn
          },
        });
        if (res.status === 200) {
          setModalLoader(false)
          setAddKnowledgeBaseModal(false)
          toast.success("Saved successfully");
          resetState()
          getQuestionDetails()
        } else {
          setModalLoader(false)
          toast.error("Something went wrong while saving data");
        }
      } else {
        setModalLoader(false)
        toast.warning("Please fill all details");
      }

    } catch (e) {
      setModalLoader(false)
      toast.error("Something went wrong while saving data");
    }
  };

  const handleEditKB = async () => {
    setModalLoader(true);
    try {
      if (question !== "" || answer !== "") {
        const userData = getSavedUserData();
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/knowledgeBase/update`,
          {
            method: "POST",
            body: JSON.stringify({
              question: question,
              answer: answer,
              uid: editMode,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: userData.tkn,
            },
          }
        );
        if (res.status === 200) {
          setModalLoader(false);
          setAddKnowledgeBaseModal(false);
          toast.success("Updated successfully");
          resetState()
          getQuestionDetails()
        } else {
          setModalLoader(false);
          toast.error("Something went wrong while saving data");
        }
      } else {
        setModalLoader(false);
        toast.warning("Please fill all details");
      }
    } catch (e) {
      setModalLoader(false);
      toast.error("Something went wrong while saving data");
    }
  };

  const resetState = () => {
    setanswere("");
    setQuestion("");
  }

  const openEditMode = (questionData) => {
    console.log("questionData", questionData)
    setAddKnowledgeBaseModal(true)
    setanswere(questionData?.answer);
    setQuestion(questionData?.question);
    setEditMode(questionData?.uid)
  }
  const handleDeleteQuestion = async () => {
    setDeleteConfirmationModal(false)
    setDeleteQuestion("")
    setLoading(true)
    try {
      const userData = getSavedUserData();
      const res = await fetch(`${process.env.REACT_APP_API_URL}/knowledgeBase/delete`, {
        method: "POST",
        body: JSON.stringify({
          uid: deleteQuestion?.uid,
        }),
        headers: {
          "Content-Type": "application/json",
          "Authorization": userData.tkn
        },
      });
      if (res.status === 200) {
        setLoading(false)
        toast.success("Deleted successfully");
        getQuestionDetails()
      } else {
        setLoading(false)
        toast.error("Something went wrong while saving data");
      }

    } catch (e) {
      setLoading(false)
      toast.error("Something went wrong while saving data");
    }
  }

  const openDeleteConfirmationModal = (questionInfo) => {
    setDeleteConfirmationModal(true)
    setDeleteQuestion(questionInfo)
  }

  const handleUrlChange = ({ target: { value } }) => {
    setUrl(value);
  };
  const handleSubmit = () => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (urlPattern.test(url)) {
      alert("added successfully")
    } else {
      alert("Invalid URL")
    }

  }
  const handleNextPage = () => {
    setPage((prev) => prev + 1)
  }

  const handlePreviousPage = () => {
    setPage((prev) => prev - 1)
  }
  return (
    <div className="agent-background question-scroll">
      <NavBar selected={"knowledgeBase"} />
      {loading && <div className="common-spinner"> <Spinner style={{ zIndex: 100, marginBottom: "20px" }} color="dark">Loading...</Spinner></div>}
      <Container fluid className="mt-3">
        <ButtonGroup className="knowledgeBase-tabs">
          <Button
            color="primary"
            outline
            onClick={() => setSelectedTab(1)}
            active={selectedTab === 1}
          >
            Flash card
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => setSelectedTab(2)}
            active={selectedTab === 2}
          >
            Add URL
          </Button>
        </ButtonGroup>
      </Container>

      {selectedTab === 1 && <Container fluid className="question-cards-outer">
        <Row>
          <Col md={4} lg={3} xl={3} xxl={2} className="mb-2">
            <div className="knowledge-base">
              <div onClick={handleAddToKBCard}>
                <i class="fa-solid fa-arrow-up-from-bracket"></i>
                <h6>Add knowledge base</h6>
              </div>
            </div>
          </Col>

          {knowledgeData && knowledgeData?.map((knowledgeItem) => {
            return (
              <Col md={4} lg={3} xl={3} xxl={2} className="mb-2">
                <div className="question-cards">
                  <span className="trash" onClick={() => openDeleteConfirmationModal(knowledgeItem)} ><i class="fa-regular fa-trash-can"></i></span>
                  <div>
                    <h5>{`${knowledgeItem?.question}`}</h5>
                    <h6 className="knowledge-item">{`${knowledgeItem?.answer}`}</h6>
                  </div>
                  <div className="d-flex justify-content-end action-btns">
                    <Button>
                      <span onClick={() => openEditMode(knowledgeItem)}>Edit<i class="fa-regular fa-pen-to-square"></i></span>
                    </Button>
                  </div>
                </div>
              </Col>

            )
          })}
        </Row>
      </Container>}
      <Pagination className="pagination-nav" aria-label=" Page navigation example" size="sm">
        <PaginationItem disabled={page === 1}>
          <PaginationLink previous onClick={handlePreviousPage}>
            <i class="fa-solid fa-angles-left"></i> Previous
          </PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={page === totalPages}>
          <PaginationLink next onClick={handleNextPage}>
            Next <i class="fa-solid fa-angles-right"></i>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
      {
        selectedTab === 2 &&
        <div className="agent-card">
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label className="mb-2">URL (BETA)</Label>
                  <Input
                    id="exampleText"
                    name="text"
                    type="text"
                    value={url}
                    onChange={(e) => handleUrlChange(e)}
                  />
                  <FormText>
                    <img src={alertIcon} alt="alert" />
                    Content of URL added here will be thoroughly studied by our AI to train itself for handling any kind of assistance to your customers.
                  </FormText>
                </FormGroup>


              </Form>
              <div className="card-bottom-btn">
                <Button disabled
                  onClick={handleSubmit}
                >Add</Button>
              </div>
            </CardBody>
          </Card>
        </div>
      }
      <Modal
        centered
        className="email-category-modal integrate-modal"
        isOpen={addKnowledgeBaseModal}
        toggle={handleAddKBModal}
      >
        <ModalHeader toggle={handleAddKBModal}>{editMode === "" ? "Add flash card" : "Edit flash card"}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label className="mb-2">Question</Label>
              <Input
                id="exampleText"
                name="text"
                type="text"
                value={question}
                onChange={(e) => handleQuestionChange(e)}
              />
              <FormText>
                {/* <img src={alertIcon} alt="alert" /> */}
                Example - When can I visit the clinic?
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label className="mb-2">Answer</Label>
              <Input
                className="answer-textarea"
                id="exampleText"
                name="text"
                type="textarea"
                value={answer}
                onChange={(e) => handleAnswereChange(e)}
              />
              <FormText>
                {/* <img src={alertIcon} alt="alert" /> */}
                Example - We are open from Monday to Friday 9 am to 5 pm.
              </FormText>
            </FormGroup>

          </Form>
        </ModalBody>
        <ModalFooter>
          {modalLoader && <div className="common-spinner"><Spinner style={{ zIndex: 100, marginBottom: "20px" }} color="dark">Loading...</Spinner></div>}
          <Button color="secondary" outline onClick={handleCancelKBcard}>
            Cancel
          </Button>
          <Button color="primary" onClick={editMode === "" ? handleCreateKB : handleEditKB}>
            {editMode === "" ? "Create" : "Update"}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        className="email-category-modal integrate-modal"
        isOpen={deleteConfirmationModal}
        toggle={handleDeleteConfirmationModal}
      >
        <ModalHeader toggle={handleDeleteConfirmationModal}>Delete confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete?
        </ModalBody>
        <ModalFooter>
          {modalLoader && <div className="common-spinner"><Spinner style={{ zIndex: 100, marginBottom: "20px" }} color="dark">Loading...</Spinner></div>}
          <Button color="secondary" outline onClick={handleDeleteConfirmationModal}>
            No
          </Button>
          <Button color="primary" onClick={handleDeleteQuestion}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div >
  );
}

export default AgentDescription;