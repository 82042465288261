import React, { useState, useContext } from "react";

export const StateContext = React.createContext();

export default function StateProvider(props) {
  const [agentData, setAgentData] = useState({});
  const [bookingsData, setBookingsData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [actionData, setActionData] = useState({});
  const [subscriptionData, setSubscriptionData] = useState({});

  return (
    <StateContext.Provider
      value={{
        agentData,
        bookingsData,
        dashboardData,
        actionData,
        subscriptionData,
        setSubscriptionData,
        setAgentData,
        setBookingsData,
        setActionData,
        setDashboardData,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}
