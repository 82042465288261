
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/App.scss';
import StateProvider from './utils/context/state_context'

function App() {

  return (
      <GoogleOAuthProvider clientId={"827024661048-skjhlhariqlb7nq55maike7ocuiojhjr.apps.googleusercontent.com"}>
        <BrowserRouter>
        <StateProvider>
          <Routes />
        </StateProvider>
        </BrowserRouter>
      </GoogleOAuthProvider>
  );
}

export default App;
