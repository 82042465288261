import React from "react";
import SupportImage from "../assets/images/support-image.png";
import Call from "../assets/images/call.png";
import Email from "../assets/images/email.png";
import Chat from "../assets/images/comment.png";
import NavBar from "../components/navbar";

const Support = () => {
  return (
    <div className="agent-background">
      <NavBar selected={"support"} />
      <div className="support-page-wrapper">
        <div className="support-page-container">
          <div className="heading-container">
            <p> If you are Facing any issues Contact us: </p>
          </div>
          <div className="image-container">
            <img src={SupportImage} alt="icon" />
          </div>
          <div className="contact-container">
            <div className="contact-box">
              <div className="icon">
                <img src={Call} alt="icon" />
              </div>
              <p> +19254938805 </p>
            </div>
            <div className="contact-box">
              <div className="icon">
                <img src={Email} alt="icon" />
              </div>
              <p>support@quicksnap.ai </p>
            </div>
            {/* <div className="contact-box">
              <div className="icon">
                <img src={Chat} alt="icon" />
              </div>
              <p>Chat with us </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
