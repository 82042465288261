/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
// import CONFIG from "../utils/constants";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";
// import { useGoogleLogin } from "@react-oauth/google";
// import GoogleIcon from "../../assets/images/google.png";
import GoogleButton from "react-google-button";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { toast } from 'react-toastify';

function OAuth() {
  // const {
  //   API_URL: { BASE_URL, getLoginURL },
  //   GOOGLE_SCOPE,
  //   GOOGLE_AUTH_SETTINGS,
  // } = CONFIG;

  const [loader, setLoader] = useState(false);

  const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REACT_APP_GOOGLE_REDIRECT_URI = process.env.REACT_APP_FRONTEND_URL;
  // const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${REACT_APP_CLIENT_ID}&scope=openid%20profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send&redirect_uri=${REACT_APP_GOOGLE_REDIRECT_URI}&prompt=consent&access_type=offline&response_type=code&include_granted_scopes=true&enable_granular`;
  const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${REACT_APP_CLIENT_ID}&scope=openid%20profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.labels%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify&redirect_uri=${REACT_APP_GOOGLE_REDIRECT_URI}&prompt=consent&access_type=offline&response_type=code&include_granted_scopes=true&enable_granular`;
  // const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${REACT_APP_CLIENT_ID}&scope=openid%20profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.labels&redirect_uri=${REACT_APP_GOOGLE_REDIRECT_URI}&prompt=consent&access_type=offline&response_type=code&include_granted_scopes=true&enable_granular`;
  const handleGoogleLogin = () => {
    window.location.href = authUrl;
  };

  const navigate = useNavigate();
  useEffect(() => {
    const authorizationCode = new URLSearchParams(window.location.search).get(
      "code"
    );
    if (authorizationCode) {
      getAccessToken(authorizationCode);
    }
  }, [navigate]);

  const handleNavigation = (resData) => {
    console.log("resData", resData)
    if (!resData?.userData?.isFreeTrialTaken) {
      navigate("/subscription");
    }
    else if(resData?.userData?.agentDescription && resData?.userData?.questionSet){
      navigate("/dashboard");
    }else if(!resData?.userData?.agentDescription){
      // navigate("/agent-description");
      navigate("/dashboard");
    }else if (!resData?.userData?.questionSet){
      navigate("/actions");
    }
  }

  const getFeatureStatus = async(userData)=> {
    console.log("getFeatureStatus",userData)
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/getFeatureStatus`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: userData.tkn
        }
      }
    );
    const data = await res.json();
    console.log("getFeatureStatus--->", data)
    if (res.status === 200) {
      console.log("data", data);
      localStorage.setItem("featureStatus", JSON.stringify(data?.status));
      setLoader(false)
    }
  }

  const getAccessToken = async (code) => {
    setLoader(true)
    try {
      if (code) {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/getAndSaveToken`, {
          method: "POST",
          body: JSON.stringify({
            token: code,
            redirectURL: process.env.REACT_APP_FRONTEND_URL,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        if (res.status === 200 && data?.success) {
          console.log("data", data);
          localStorage.setItem("userdata", JSON.stringify(data));
          // await getFeatureStatus(data)
          setLoader(false)
          handleNavigation(data);
        } else {
          setLoader(false)
          toast.error("Something went wrong while authenticating");
          navigate("/");
        }
      }
    } catch (err) {
      setLoader(false)
      toast.error("Something went wrong while authenticating");
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="button-loader">
        <GoogleButton onClick={handleGoogleLogin} />
        {loader && <Spinner color="primary">Loading...</Spinner>}
      </div>
      {/* {loader && (
        <div id="loader-overlay">
          <div className="loader"></div>
        </div>
      )} */}
    </React.Fragment>
  );
}

export default OAuth;

