/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import OAuth from "./OAuth";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
  Form,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
} from "reactstrap";

import intelligenceAi from "../assets/images/ai-intelligence.png";
import logo from "../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import tabImg1 from "../assets/images/tab-img1.webp";
import tabImg2 from "../assets/images/tab-img2.webp";
import ReactPlayer from "react-player";
import frontDeskAi from "../assets/images/front-desk-Ai.png"

export default () => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(true);
  const [serviceTab, setServiceTab] = useState("1");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    if (userData?.tkn) {
      if (!userData?.userData?.isFreeTrialTaken) {
        navigate("/subscription");
      } else {
        // navigate("/agent-description");
        navigate("/dashboard");
      }
    }
  }, []);

  return (
    <div className="login-container">
      <Container>
        <Row className="login-content">

          <Col xs={12} sm={12} md={5} lg={5} xl={4}>
            <div className="logo-header">
              <img src={logo} alt="logo-icon" />
            </div>
            <div className="card-inner">
              <h2>Sign in to your Quicksnap AI Account.</h2>
              <p>
                “Welcome to our intuitive front desk AI system, where scheduling
                complexities are a thing of the past – let us handle the details
                while you focus on your business.”
              </p>
              <div className="login-btn">
                <OAuth />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xl={6}>
            <div className="right-image-section">
              <img className="front-desk-ai" src={frontDeskAi} alt="frontDeskAi" />
              {/* <Nav tabs>
                <NavItem>
                  <NavLink
                    className={serviceTab == "1" ? "active" : ""}
                    onClick={() => setServiceTab("1")}
                  >
                    Car Service
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={serviceTab == "2" ? "active" : ""}
                    onClick={() => setServiceTab("2")}
                  >
                    Dental Clinic
                  </NavLink>
                </NavItem>
              </Nav> */}
              {/* <TabContent activeTab={serviceTab == "1" ? "1" : "2"}>
                {serviceTab == "1" && <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="tab-img">
                        <img src={tabImg1} alt="tabImg1" />
                      </div>

                    </Col>
                  </Row>
                </TabPane>}
                {serviceTab == "2" && <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="tab-img">
                        <img src={tabImg2} alt="tabImg2" />
                      </div>

                    </Col>
                  </Row>
                </TabPane>}
              </TabContent> */}
              <Form></Form>
            </div>
          </Col>


        </Row>

      </Container>
      {showVideo && <div className="video-wrapper">
        <button
          type="button"
          className="close"
          // data-dismiss="modal"
          // aria-label="Close"
          onClick={() => setShowVideo(false)}
        >
          <span style={{
            position: "relative",
            top: "-5px",
            left: "1px"
          }}>&times;</span>
        </button>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=yW7kDuz8I0E"
          playing={false}
          controls={true}
          volume={null}
          muted={false}
          width={300}
          height={'unset'}
        />
      </div>}
    </div>
  );
};
