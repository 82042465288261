import { useLocation, useNavigate } from "react-router-dom";

const NavigationHOC = (WrappedComponent) => {
  const HOC = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    return <WrappedComponent redirect={navigate} path={pathname} {...props} />;
  };
  return HOC;
};

export default NavigationHOC;
